import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import FormHandler from "../utils/FormHandler";
import FeatherIcon from 'feather-icons-react';
import axios from "axios";
import {toggleLoader} from "../actions/setting";
import {useDispatch} from "react-redux";
import Dlogo from "../../images/dunlogo.png";
import {goToHomePage} from "../../utils/Utils";
import {getAccessToken, loadCredential, setUsername} from "../utils/SharedAuthentication";
import validateLogin from "../utils/SharedFormValidationRules";
import Logo from "../../images/logo.png";

const Login = (props) => {
  const [showText, setShowText] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const dispatch = useDispatch();


  let history = useHistory();


  useEffect(() => {
    if (getAccessToken()) {
      history.push("/home")
    }
  }, [])

  const {
    values,
    errors,
    handleChange,
    handleOnBlur,
    handleSubmit,
  } = FormHandler(login, validateLogin);


  useEffect(() => {
    let token = props.location?.search ? new URLSearchParams(props.location.search).get("Token") : '';

    if (token) {
      dispatch(toggleLoader(true));
      axios.post(process.env.REACT_APP_HOST + "/register-login-third-party", {
        "key": token,
        "authType": "KEELLS"
      }).then((response) => {
        onLoginSuccess(response);
        dispatch(toggleLoader(false));

      }).catch((error) => {
        dispatch(toggleLoader(false));
        setErrMsg("Keells Login Issue");
      })
    }

    // if (getCookie("auth_cookie")) {
    //   dispatch(toggleLoader(true));
    //   axios.post(process.env.REACT_APP_HOST + "/register-login-third-party", {
    //     "key": getCookie("auth_cookie"),
    //     "authType": "KEELLS"
    //   }).then((response) => {
    //     console.log("values", values, "response", response)
    //     onLoginSuccess(response);
    //     dispatch(toggleLoader(false));
    //
    //   }).catch((error) => {
    //     dispatch(toggleLoader(false));
    //     console.log(error)
    //     setErrMsg("Keells Login Issue");
    //   })
    // }
  }, [])

  function onLoginSuccess(response) {
    if (response.data.role === 100000 || response.data.role === 6) {//xindicate user
      setErrMsg("You don't have permission")

      // throw Error({customError:"You don't have permission"});
    } else {
      setUsername(response.data.username ? response.data.username : response.data.name);
      loadCredential(response.data);

      const reqUrl = props.location && props.location.query && props.location.query.pathname;

      if (!reqUrl || reqUrl.includes("login") || reqUrl.includes("reset") || reqUrl.includes("sign-up")) {
        goToSmartHome();
      } else {
        props.history.push(reqUrl);
      }
    }
  }

  function login() {
    dispatch(toggleLoader(true));

    const params = new URLSearchParams()

    params.append("password", values.password);
    params.append("username", values.username);
    params.append("client_id", "senzagro-client");
    params.append("client_secret", "senzagro-secret");
    params.append("grant_type", "password");

    axios.post(process.env.REACT_APP_HOST + `/oauth/token`, params)
      .then(function (response) {
        response.data.username = values.username
        onLoginSuccess(response);
        dispatch(toggleLoader(false));
      })
      .catch(function (error) {
        //
        //
        if (error.response && error.response.status === 401) {
          setErrMsg(error.response.data.error_description);
        } else {
          setErrMsg("UserName or Password is Wrong")
        }
        dispatch(toggleLoader(false));
      })
  }

  function goToSmartHome() {
    goToHomePage(props.history);
  }

  return (
    <div className="login-background" id="login">
      <div className="login-inner">
        <div className="login-box">
          <div className="login-logo">
            {process.env.REACT_APP_DOMAIN === "DUNYA" ? (
              <img className="dunya-logo-image" src={Dlogo} alt="logo"/>
            ) : (
              <img className="login-logo-image" src={Logo} alt="logo"/>
            )}
          </div>

          <form onSubmit={handleSubmit} noValidate>
            <div className="form-group m-b-16">
              <label htmlFor="email">Username</label>
              <input type="text" value={values.username || ''} onChange={handleChange} onBlur={handleOnBlur}
                     className={errors.username ? "form-control warning-input" : "form-control"}
                     name="username" placeholder="Enter your username"/>
              {errors.username && (
                <p className="warning-input-msg">{errors.username}</p>)}
            </div>
            <div className="form-group m-b-6">
              <label htmlFor="pwd">Password</label>
              <input type={!showText ? "password" : "text"} value={values.password || ''} onChange={handleChange}
                     onBlur={handleOnBlur}
                     className={errors.password ? "form-control warning-input" : "form-control"}
                     name="password"
                     placeholder="Enter your password"/>
              <span className="field-icon" onClick={() => setShowText(!showText)}><FeatherIcon
                className="toggle-password" icon={showText ? "eye" : "eye-off"}/></span>
              {errors.password && (
                <p className="warning-input-msg">{errors.password}</p>)}
            </div>
            {errMsg && (<div id={'loginWarning'} className={"login-warning-msg"}>{errMsg}</div>)}
            <div className="login-text-btn m-b-9">
              Forgot password?<Link to={"/forgot-password"}> RESET</Link>
            </div>

            <button className="btn btn-sa-primary w-100 m-b-14" type={"submit"}>LOGIN</button>
          </form>
        </div>
      </div>
    </div>);
};


export default Login
