import React, {useEffect, useState} from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Dropdown from "react-bootstrap/Dropdown";
import Container from "react-bootstrap/Container";
import {NavLink, useLocation} from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import avatarImg from "../images/avatar.png";
import translate from "../images/google-translate.svg";
import {useTranslation} from "react-i18next";
import translateTick from "../images/icon-green.png";
import {find} from "underscore";
import axios from "axios";
import {getNotificationDot, getUserId} from "../utils/SharedAuthentication";
import {formatDisplayEnumValue, getNotificationIcon, timeSince} from '../utils/utils';
import {useDispatch, useSelector} from "react-redux";
import {getFilterCorporateList} from "../../actions/module";
import * as _ from "underscore";

export default function NavbarLayout(props) {

  let loadNotifications = props.loadNotifications
  let navItems = props.navItems
  let languages = props.languages
  let changeLanguage = props.changeLanguage
  let settingList = props.settingList
  let settingPermission = props.settingPermission
  let mobileNavbar = props.mobileNavbar
  let expandPageNotification = props.expandPageNotification
  let txts = props.txts
  let brand = props.brand
  let hideNotification = props.hideNotification
  let hideTranslation = props.hideTranslation
  let corporateEnable = props.corporateEnable ? props.corporateEnable : false
  let corporateList = props.corporateList ? props.corporateList : []
  let notificationUpdateRefresh = props.notificationUpdateRefresh
  let notificationID = props.notificationID
  let isNotificationExpand = props.isNotificationExpand
  let goToNotification = props.goToNotification

  const dispatch = useDispatch();
  const selectedCorporate = useSelector((state) => {
    return state.modules.selectedCorporate;
  });
  const [dropDown, setDropDown] = useState(false);
  const {t, i18n} = useTranslation();
  const [dropDownNotification, setDropDownNotification] = useState(false);
  const [sideMenu, setSideMenu] = useState(false);
  const [dropDownSetting, setDropDownSetting] = useState(false);
  const [dropDownTranslate, setDropDownTranslate] = useState(false);
  const [corporateDropDown, setCorporateDropDown] = useState(false);
  const [visibleNotifications, setVisibleNotifications] = useState(7);
  const [searchValue, setSearchValue] = useState('');
  const location = useLocation();
  const showMoreNotifications = () => {
    setVisibleNotifications(visibleNotifications + 10);
  };
  const corporateDataList = useSelector(state => {
    return state.modules.corporateList
  });
  const corporateListFilter = useSelector(state => {
    return state.modules.corporateListFilter
  });
  const toggle = () => {
    if (dropDownNotification) {
      setDropDownNotification(false);
    } else {
      setDropDownNotification(true);
    }
  };
  const navSetting = () => {
    if (dropDownSetting) {
      setDropDownSetting(false);
    } else {
      setDropDownSetting(true);
    }
  };
  const navTranslate = () => {
    if (dropDownTranslate) {
      setDropDownTranslate(false);
    } else {
      setDropDownTranslate(true);
    }
  };
  const navCorporate = () => {
    if (corporateDropDown) {
      setCorporateDropDown(false);
    } else {
      setCorporateDropDown(true);
    }
  };


  const [lang, setLang] = useState("en");


  useEffect(() => {
    const LoadExternalScript = () => {
      const externalScript = document.createElement("script");
      externalScript.type = "text/javascript";
      document.body.appendChild(externalScript);
      externalScript.src = `//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit`;
    };
    LoadExternalScript();
  }, []);

  useEffect(() => {
    if (!selectedCorporate && !notificationUpdateRefresh) {
      return
    }
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/corporate/` + selectedCorporate + `/notifications?pageSize=100&pageIndex=0`)
      .then((res) => {
        props.setNotifications(res.data.content.content);
      })
      .finally(() => {
      });
  }, [notificationUpdateRefresh, selectedCorporate]);

  useEffect(() => {
    if (!selectedCorporate) {
      axios.get(`${process.env.REACT_APP_HOST}/user/${getUserId()}/notification/size/${100}/index/0`)
        .then((res) => {
          props.setNotifications(res.data.content);
        })
        .finally(() => {
        });
    }
  }, [selectedCorporate]);

  function removeNotificationBadge() {
    if (loadNotifications.length === 0) {
      return;
    }

    // props.setNotificationUpdateRefresh(!notificationUpdateRefresh);
    localStorage.setItem("NOTIFICATION", loadNotifications[0].createdAt);
  }

  function notificationDot() {
    return loadNotifications.length > 0 &&
      loadNotifications.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      }) &&
      loadNotifications[0].createdAt !== getNotificationDot() && (
        <div className={"notification-dot position-absolute"}/>
      )
  }

  function notificationCard(notification) {
    return (<div
      key={
        notification.id
          ? notification.id
          : notification.reference
      }
    >
      <div className="notification-details w-auto rounded-0 shadow-none sa-cursor"
           id={"notifications"}
           onClick={(e) => {
             e.persist();
             if (notification.farmId && (e.target.parentNode.id === "notifications" || e.target.parentNode.id === "notification-title" || e.target.parentNode.id === "notification-msg")) {
               goToNotification(notification.farmId, notification.plotId, notification.title);
             }
           }}
      >
        {getNotificationIcon(notification.title)}
        <div id={"notification-title"}
             key={notification.id + "div"}
             className="notification-title limit-chara p-t-8">
          {notification.title ? formatDisplayEnumValue(notification.title) : notification.type.replaceAll("_", " ")}
        </div>
        <div id={"notification-msg"}
             className={notificationID === (notification.id ? notification.id : notification.reference) ? "notification-description expand-notification" : "notification-description limit-chara"}>
          {notification.message}
        </div>
        <div
          className={
            notificationID ===
            (notification.id
              ? notification.id
              : notification.reference)
              ? "notification-since-time expand-notification"
              : "notification-since-time limit-chara d-inline"
          }
        >
          {timeSince(notification.createdAt)}
        </div>
        {isNotificationExpand &&
        notificationID ===
        (notification.id
          ? notification.id
          : notification.reference) ? (
          <button
            className="button-ellipsis expand-button-ellipsis bg-transparent"
            onClick={() =>
              props.toggleNotificationTruncate(
                false,
                null,
                notification.seenStatus
              )
            }
          >
            <FeatherIcon
              icon={"chevron-up"}
              width={"20px"}
            />
          </button>
        ) : (
          <button
            className="button-ellipsis bg-transparent"
            onClick={() =>
              props.toggleNotificationTruncate(
                true,
                notification.id
                  ? notification.id
                  : notification.reference,
                notification.seenStatus
              )
            }
          >
            <FeatherIcon
              icon={"chevron-down"}
              width={"20px"}
            />
          </button>
        )}
      </div>
    </div>)
  }

  function clickArrowDown() {
    setDropDown(true);
  }

  function clickArrowUp() {
    setDropDown(false);
  }

  function handleSearchCorporate(e) {
    let val = e.target.value;
    setSearchValue(val)
    if (val !== "") {
      let res = _.filter(corporateDataList, function (item) {
        return _.values(_.pick(item, 'name')).toString().toLocaleLowerCase().includes(val.toLocaleLowerCase());
      });
      dispatch(getFilterCorporateList([...res]));
    } else {
      dispatch(getFilterCorporateList([...corporateDataList]));
    }
  }

  useEffect(() => {
    setSearchValue('')
  }, [location.pathname])

  return !sideMenu ? (
    <Navbar className={"sm-navbar"} expand="md">
      <Container className={"sa-navbar-container"}>
        {/*<Navbar.Toggle aria-controls="basic-navbar-nav" />*/}
        <Navbar.Brand>
          {brand()}
        </Navbar.Brand>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto collapse-navbar navbar-top">

            {navItems.map((data, index) => data.permissions && data.webScreen && <NavLink key={index + "nav"}
                                                                                          to={data.to}
                                                                                          isActive={data.isActive}
                                                                                          activeClassName="nav-active">
              {data.title}
            </NavLink>)}

          </Nav>

        </Navbar.Collapse>

        <span className={"ml-auto row setting-element-space align-items-center"}>
            {corporateEnable && corporateDataList.length !== 1 && (
              <Dropdown
                show={corporateDropDown}
                onToggle={navCorporate}
                alignRight
                className={"nav-setting"}
              >
                <Dropdown.Toggle name="btnBell" id="dropdown-custom-components-bell">
                  <div className={"corporate-dropDown"}>
                    <FeatherIcon icon={"refresh-ccw"} className={"color-green"} width={'20px'}/>
                    <div className={"ml-2 corporate-name"}>
                      {(
                        !props.selectedCorporate ? "All corporates"
                          : find(corporateDataList, {id: props.selectedCorporate}) ? find(corporateDataList, {id: props.selectedCorporate}).name : " "

                      )}
                    </div>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu
                  style={{width: "300px", height: "500px", right: "52px"}}
                  className={"single-card-content-corporate localization"}
                >
                  <div className="setting-text">{t("title.CORPORATES")}</div>
                  <span className="sa-table-btn-mute sa-search-align border-0 h-36 w-100-all">
                                <input className={'sa-table-search sa-table-search-point font-14'}
                                       name={t("placeHolder.SEARCH")}
                                       value={searchValue || ''}
                                       onChange={handleSearchCorporate}
                                       autoComplete={"off"} type="text" placeholder="Search"/>
                                <FeatherIcon className={'sa-search-icon'}
                                             icon={"search"} width={'16px'}/>
                  </span>
                  {/* Option for selecting all corporates */}
                  {!props.fmsOrCco && (
                    <div onClick={() => {
                      props.corporateChange("");
                      setCorporateDropDown(false);
                    }} className="row setting-hover-effect" style={{marginTop: 8}}>
                      <div className="col-12">
                        <div className="setting-details" style={{height: "52px"}}>
                          <div className="sa-icon-setting">{"A"}</div>
                          <div id="settings" className={"setting-title"}>
                            {"All Corporates"}
                            {!props.selectedCorporate && (
                              <div className="translate-tick">
                                <FeatherIcon
                                  icon={"check-circle"}
                                  width={"16px"}
                                />
                              </div>
                            )}
                          </div>
                          <div className={"setting-description"}/>
                        </div>
                      </div>
                    </div>
                  )}


                  {/* Existing corporate options */}
                  {corporateListFilter.map((data, index) => (
                    <div key={"ln" + index} onClick={() => {
                      props.corporateChange(data.id);
                      setCorporateDropDown(false);
                    }} className="row setting-hover-effect" style={{marginTop: 8}}>
                      <div className="col-12">
                        <div className="setting-details" style={{height: "52px"}}>
                          <div className="sa-icon-setting">{data.name[0]}</div>
                          <div id="settings" className={"setting-title"}>
                            {data.name}{" "}
                            {data.id === props.selectedCorporate && (
                              <div className="translate-tick">
                                <FeatherIcon
                                  icon={"check-circle"}
                                  width={"16px"}
                                />
                              </div>)}
                          </div>
                          <div className={"setting-description"}/>
                        </div>
                      </div>
                    </div>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          {languages.length > 0 && <Dropdown
            show={dropDownTranslate}
            onToggle={navTranslate}
            alignRight
            className={"nav-setting"}
          >
            {!hideTranslation && (
              <Dropdown.Toggle
                name="btnBell"
                id="dropdown-custom-components-bell"
              >
                <div>
                  <img
                    style={{width: "22px", height: "22px"}}
                    className="user-profile-image profile-center"
                    src={translate}
                    alt="profile"
                  />
                </div>
              </Dropdown.Toggle>)}
            <Dropdown.Menu
              style={{width: "236px", height: "264px"}}
              className={"single-card-content-setting localization"}
            >
              <div className="setting-text">{txts.language}</div>

              {languages.map((data, index) => (<div key={"ln" + index} onClick={() => {
                changeLanguage(data.lng);
                setLang(data.lng);
                setDropDownTranslate(false);
              }}
                                                    className="row setting-hover-effect" style={{marginTop: 8}}>
                <div className="col-12">
                  <div className="setting-details" style={{height: "52px"}}>
                    <div className="sa-icon-setting">{data.icon}</div>
                    <div id="settings" className={"setting-title"}>
                      {data.title}{" "}
                      {lang === data.lng && (
                        <div className="translate-tick">
                          <img className="icon-in-nav" src={translateTick} alt={"translation"}/>
                        </div>)}
                    </div>
                    <div className={"setting-description"}/>
                  </div>
                </div>
              </div>))}

            </Dropdown.Menu>
          </Dropdown>}
          {settingPermission && (
            <Dropdown
              show={dropDownSetting}
              onToggle={navSetting}
              alignRight
              className={"nav-setting"}
            >
              <Dropdown.Toggle
                name="btnBell"
                id="dropdown-custom-components-bell"
              >
                <div>
                  <FeatherIcon
                    className={
                      dropDownSetting
                        ? "setting-icon setting-icon-active"
                        : "setting-icon"
                    }
                    icon="settings"
                  />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className={"single-card-content-setting"}>
                <div className="setting-text">{t("title.SETTINGS")}</div>
                {settingList.map((data, index) => data.permission && (<div key={index + "setting"}
                                                                           onClick={() => {
                                                                             data.click()
                                                                             setDropDownSetting(false);
                                                                           }}
                                                                           className="row setting-hover-effect"
                                                                           style={{marginTop: 16}}
                  >
                    <div className="col-12">
                      <div className="setting-details">
                        <div className="sa-icon-setting">S</div>
                        <div
                          id="settings"
                          className={"setting-title"}
                        >
                          {data.title}
                        </div>
                        <div className={"setting-description"}>
                          {data.desc}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              </Dropdown.Menu>
            </Dropdown>
          )}

          {!hideNotification && (
            <Dropdown show={dropDownNotification} onToggle={toggle} alignRight>
              <Dropdown.Toggle
                name="btnBell"
                id="dropdown-custom-components-bell"
              >
                <div
                  onClick={() => {
                    removeNotificationBadge();
                  }}
                  className={"position-relative"}
                >
                  {notificationDot()}

                  <FeatherIcon

                    className={
                      props.notificationActive ? "bell-icon-b bell-icon-active"
                        : "bell-icon-b"
                    }
                    icon="bell"
                  />
                  {dropDownNotification ? (
                    <div
                      onClick={clickArrowUp.bind(this)}
                      className="bell-icon text-black"
                    />
                  ) : (
                    <div
                      onClick={clickArrowDown.bind(this)}
                      className="bell-icon"
                    />
                  )}
                  {/*{*/}
                  {/*  <div className={"circle-icon-overlap"}>*/}
                  {/*    <FeatherIcon className="circle-in" icon="circle" />*/}
                  {/*  </div>}*/}
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu
                id={"dropdown-notification"}
                className={"single-card-content-notification p-0"}
              >
                <div>
                  <div className="row sa-notification-sticky px-3">
                    <div className="notification-text col-md-10"> {txts.notifications}</div>
                    <span className={'col-md-2 sa-cursor '}>
                  <span className="m-l-24" onClick={expandPageNotification}>
                <FeatherIcon
                  icon={"external-link"}
                  width={"20px"}
                />
                  </span>
                </span>
                  </div>

                  <div className="row" style={{maxHeight: '630px', overflowY: 'auto'}}>
                    <div className="col-12">
                      {loadNotifications.length > 0 &&
                        loadNotifications
                          .slice(0, visibleNotifications)
                          .sort(function (a, b) {
                            return new Date(b.createdAt) - new Date(a.createdAt);
                          })
                          .map(
                            (notifications) =>
                              notifications.seenStatus === false && notificationCard(notifications)
                          )}
                      {loadNotifications.length > visibleNotifications && (
                        <div style={{textAlign: 'right'}}>
                          <button onClick={showMoreNotifications}>
                            {t("title.VIEW_MORE")}
                          </button>
                        </div>
                      )}
                      {loadNotifications.length === 0 && <div className={"empty-results"}>
                        <FeatherIcon icon="info"/>
                        <div className={"empty-results-text"}>{txts.emptyNotifications}
                        </div>
                      </div>}
                    </div>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>)}
          <span className={"col-3"}>
            <FeatherIcon
              onClick={() => setSideMenu(true)}
              className={"side-menu-btn"}
              icon="menu"
            />
          </span>
        </span>
        <Dropdown id={"profileDropdown"} alignRight>
          <Dropdown.Toggle id="dropdown-custom-components">
            <div className="user-profile ">
              <div className="user-profile-inner">
                <img
                  className="user-profile-image profile-center"
                  src={avatarImg}
                  alt="profile"
                />
              </div>
            </div>
            <span className={"mobile-hide"} id={"profileName"}>
              {props.name}
            </span>
          </Dropdown.Toggle>
        </Dropdown>
        <Dropdown id={"profileDropdown"} style={{marginLeft: -13}} alignRight>
          <Dropdown.Toggle
            name="btnProfile"
            id="dropdown-custom-components-profile"
          >
            {dropDown ? (
              <div onClick={clickArrowUp.bind(this)} className="dropdown-icon ">
                <i className="fa fa-angle-down"/>
              </div>
            ) : (
              <div
                onClick={clickArrowDown.bind(this)}
                className="dropdown-icon "
              >
                <i className="fa fa-angle-down"/>
              </div>
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu style={{marginTop: 8}}>
            {mobileNavbar.map((data, index) => (<Dropdown.Item
              id="aboutSenzAgro"
              key={index + "dd"}
              onClick={data.click}
              className="active-dropdown-menu"
            >
              {data.title}
            </Dropdown.Item>))}
          </Dropdown.Menu>
        </Dropdown>
      </Container>
    </Navbar>
  ) : (
    <div className={"side-nav-container"}>
      <FeatherIcon
        onClick={() => setSideMenu(false)}
        className={"side-menu-close-btn"}
        icon="x"
      />
      <div className="user-menu-profile">
        <div className="user-profile-inner">
          <img
            className="user-profile-image profile-center"
            src={avatarImg}
            alt="profile"
          />
        </div>
      </div>
      <div className={"side-menu-name"}>{props.name}</div>


      {navItems.map((data, index) => data.permissions && <NavLink key={"nav-mobile" + index}
                                                                  to={data.to} isActive={data.isActive}
                                                                  className={"side-menu-nav "}
                                                                  onClick={() => setSideMenu(false)}
                                                                  activeClassName={"nav-active-mv"}>

        <FeatherIcon
          className={data.isActive ? "side-menu-nav-icon-active" : "side-menu-nav-icon"}
          icon={data.icon}
        />{" "}
        <span className={"m-l-10"}>{data.title}</span>
        <hr className={"border-bottom border-top-0"}/>
      </NavLink>)}


      <div className={"side-menu-footer"}>
        {mobileNavbar.map((data, index) => (<div key={index + "footer"}
                                                 className={data.logout ? "side-menu-logout" : "side-menu-nav border-bottom border-top"}
                                                 onClick={() => {
                                                   data.click()
                                                   setSideMenu(false)
                                                 }}
        >
          <FeatherIcon className={data.logout ? "side-menu-nav-log-icon" : "side-menu-nav-icon"} icon={data.icon}/>{" "}
          <span className={"m-l-10"}>{data.title}</span>
        </div>))}

      </div>
    </div>
  );
};