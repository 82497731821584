export function setUsername(username) {
  localStorage.setItem('USERNAME', username);
}

export function getUsername() {
  return localStorage.getItem('USERNAME');
}

export function getNotificationDot() {
  return localStorage.getItem('NOTIFICATION');
}

export function loadCredential(credential) {
  localStorage.setItem('ACCESS_TOKEN_SENZAGRO', credential.access_token);
  localStorage.setItem('ACCOUNT', credential.references[0]);
  localStorage.setItem('REFRESH_TOKEN', credential.refresh_token);
  localStorage.setItem('ROLE', credential.role);
  localStorage.setItem('REF_COUNT', credential.references.length);
  localStorage.setItem('REFERENCES', credential.references);
  localStorage.setItem('USER_ID', credential.id);
  localStorage.setItem('NAME', credential.name);
}

export function getRefreshToken() {
  return localStorage.getItem('REFRESH_TOKEN');
}

export function getAccount() {
  return localStorage.getItem('ACCOUNT');
}

export function getRefCount() {
  let count = localStorage.getItem('REF_COUNT');

  if (count) {
    return +count;
  }
  return count;
}

export function getReferences() {
  return localStorage.getItem('REFERENCES');
}

export function getName() {
  return localStorage.getItem('NAME');
}

export function getUserId() {
  return localStorage.getItem('USER_ID');
}

export function getRole() {
  return +localStorage.getItem('ROLE');
}

export function getAccessToken() {
  return localStorage.getItem('ACCESS_TOKEN_SENZAGRO');
}

export function isCorporateLevelUser() {
  return 100 <= getRole() && getRole() < 1000
}

export function isSuper() {
  return getRole() <= 5;
}

export function isAccountUser() {
  return getRole() === 10;
}

export function signOut() {
  localStorage.clear();
}
