import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {hasPermission,} from "../../../utils/Authentication";
import {formatDisplayEnumValue, InformativeMSG, isEmail, toCapsFirst} from "../../../utils/Utils";
import Container from "react-bootstrap/Container";
import FormHandler from "../../../shared/utils/FormHandler";
import {validateUserSubmit, validateUserUpdate} from "../../../utils/FormValidationRules";
import FeatherIcon from "feather-icons-react";
import {resetConfirmationDialog, toggleConfirmationDialog, toggleLoader} from "../../../shared/actions/setting";
import {useDispatch, useSelector} from "react-redux";
import axios from 'axios';
import {toast} from "react-toastify";
import AlertUserSetting from "./alert-user-setting";
import FilterHandler from "../../../shared/utils/FilterHandler";
import {useTranslation} from "react-i18next";
import * as _ from "underscore";
import {option} from "react-timekeeper/lib/components/styles/time-dropdown";
import {getUserId} from "../../../shared/utils/SharedAuthentication";


const PlotUser = (props) => {
  const {t, i18n} = useTranslation();

  const {plotId} = useParams();
  const [userList, setUserList] = useState([]);
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);
  const [isModelVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [formErr, setFormErr] = useState(null);
  const [suggestion, setSuggestion] = useState([]);
  const [userLogList, setUserLogList] = useState([]);
  const [logUser, setLogUser] = useState(null);
  const [page, setPage] = useState(1);
  const [resendUser, setResendUser] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isTable, setTable] = useState(false);
  const [showText, setShowText] = useState(false);
  const [userListUnFiltered, setUserListUnFiltered] = useState([]);
  const statusList = ["ACTIVE", "INACTIVE"];
  const [userRoleList, setUserRoleList] = useState(null);
  const dispatch = useDispatch();
  const confirmationDialog = useSelector(state => {
    return state.setting.confirmationDialog
  });


  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleOnBlur,
    setValue,
    initForm,
  } = FormHandler(plotUser, !isUpdateAvailable ? validateUserSubmit : validateUserUpdate);

  function plotUser() {
    setIsLoading(true);
  }

  // to get the plot user list
  useEffect(() => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + (props.plotId ? props.plotId : plotId) + '/system-user')
      .then(res => {
        setUserList(res.data.content);
        setUserListUnFiltered(res.data.content);
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });

  }, [props.plotId]);

  useEffect(() => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + '/ums/super/user-role')
      .then(res => {
        setUserRoleList(res.data.content)
      }).catch((error) => {

    }).finally(() => {
      dispatch(toggleLoader(false));
    });
  }, []);


  useEffect(() => {
    if (!isLoading) {
      return;
    }

    // to add the plot user
    if (!isUpdateAvailable) {
      dispatch(toggleLoader(true));
      values.language = values.language ? values.language : "ENGLISH"
      values.host = window.location.protocol + '//' + window.location.host + '/sign-up';
      if (!values.isTable) {
        delete values.username;
        delete values.password;
        delete values.confirmPassword;
      }
      axios.post(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + (props.plotId ? props.plotId : plotId) + '/system-user', delete values.isTable && values)
        .then(res => {
          setUserList(oldArray => [...oldArray, res.data.content]);
          toast.success(t("successMsg.USER_CREATED_SUCCESSFULLY"));
          resetFormData()
        })
        .catch(error => {
            if (error.response && error.response.status === 422) {
              setFormErr(error.response.data.message);
            }
          }
        )
        .finally(() => {
          setIsLoading(false);
          dispatch(toggleLoader(false));
          setSuggestion([]);
        });
      // to update the plot user
    } else {
      dispatch(toggleLoader(true));
      delete values.userRoleId;
      delete values.username;
      axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + (props.plotId ? props.plotId : plotId) + '/system-user/' + values.id, values)
        .then(res => {
          let newArr = [...userList];
          newArr[values.index] = res.data.content;
          setUserList(newArr);
          toast.success(t("successMsg.USER_UPDATED_SUCCESSFULLY"));
          resetFormData();
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            setFormErr(error.response.data.message);
          }
        })
        .finally(() => {
          setIsLoading(false);
          dispatch(toggleLoader(false));
          setSuggestion([]);

        });
    }
  }, [isLoading]);

  function handleDeletePlotUser(user) {
    if (user.userRoleId < 10000) {
      toast.error(t("errorMsg.YOU_DON'T_HAVE_PERMISSION_TO_DELETE"));
      return;
    }
    dispatch(toggleConfirmationDialog({
      isVisible: true,
      confirmationHeading: t("confirmation_msg.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_PLOT_USER?"),
      confirmationDescription: t("confirmation_msg.THE_DELETE_ACTION_WILL_REMOVE_THE_PLOT_USER.")
    }));
    resendUser && setResendUser(null);
    setDeleteId(user.id);
  }

  useEffect(() => {
    if (!confirmationDialog || !confirmationDialog.onSuccess) {
      return;
    }
    dispatch(resetConfirmationDialog());
    dispatch(toggleLoader(true));
    if (!resendUser) {
      axios.delete(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + (props.plotId ? props.plotId : plotId) + '/system-user/' + deleteId)
        .then(res => {
          if (res.status === 200) {
            setUserList(userList.filter(item => item.id !== deleteId));
            toast.success(t("successMsg.USER_DELETED_SUCCESSFULLY"));
          }
        })
        .finally(() => {
          dispatch(toggleLoader(false));
          setDeleteId(-1);
        })
    } else {
      axios.post(process.env.REACT_APP_HOST + '/resend-key', {
        'email': resendUser.email,
        // mobile: resendUser.mobile,
        'host': process.env.REACT_APP_HOST + '/sign-up',
      }).then(() => {
        toast.success(t("successMsg.EMAIL_HAS_BEEN_SENT_SUCCESSFULLY"));
      }).catch((error) => {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message)
        } else {
          toast.error(t("errorMsg.EMAIL_HAS_NOT_BEEN_SENT"))
        }
      })
        .finally(() => {
          setResendUser(null);
          dispatch(toggleLoader(false));
        })
    }
  }, [confirmationDialog]);


  function resetFormData() {
    setIsModalVisible(false);
    setIsUpdateAvailable(false);
    setFormErr("");
    Object.keys(values).forEach(key => delete values[key]);
    Object.keys(errors).forEach(key => delete errors[key]);
    setSuggestion([]);
    setSelectedUser(null);
    setTable(false)
  }


  function onChangeEmail(event) {
    handleChange(event);
    if (selectedUser && selectedUser.email !== event.target.value || selectedUser && !event.target.value) {
      setSelectedUser(null);
      initForm({});
    }
    if (!event.target.value) {
      return
    }
    let email = isEmail(event.target.value) ? event.target.value.trim() : event.target.value;

    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/system-user/` + 10000 + '/search' + '?email=' + email)
      .then(res => {
        setSuggestion(res.data.content);
      }).catch((error) => {

    }).finally(() => {
    });
  }

  function onSelectEmail(user) {
    setSuggestion([]);
    user.language = user.language ? user.language : "ENGLISH"
    user.username = null;
    setSelectedUser(user);
    initForm(user)
  }

  useEffect(() => {
    if (!logUser) {
      return
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + '/ums/user/' + getUserId() + '/system-user/' + logUser.id + '/history?size=' + 20 + '&page=' + (userLogList.length === 0 ? 1 : page) + '&order=' + "DESC")
      .then(res => {
        if (userLogList.length > 0) {
          res.data.content.length > 0 && res.data.content.forEach(ele => userLogList.push(ele)) && setUserLogList(userLogList);
        } else {
          setUserLogList(res.data.content);
        }
      }).catch((error) => {

    }).finally(() => {
      dispatch(toggleLoader(false));
    });
  }, [page, logUser]);


  function bottomReached() {
    if (!logUser) {
      return
    }
    const offset = document.getElementById('main3').offsetHeight;
    const scrolledHeight = document.getElementById('main3').scrollTop;
    (offset + scrolledHeight + 1) >= document.getElementById('main3').scrollHeight && setPage(page + 1);
  }

  function resendVerification(user) {
    dispatch(toggleConfirmationDialog({
      isVisible: true,
      type: 'request',
      confirmationHeading: t("confirmation_msg.ARE_YOU_SURE_YOU_WANT_RESEND_REGISTRATION_MAIL?")
      ,
      confirmationDescription: t("confirmation_msg.THE_OK_ACTION_WILL_SEND_REGISTRATION_MAIL_TO_USER?")
    }));
    setResendUser(user);
    setDeleteId(-1);
  }


  function formClick(event) {
    let names = ['suggestion', 'email'];
    if (!event.target.name || !names.includes(event.target.name)) {
      suggestion.length > 0 && setSuggestion([]);
    }
  }

  const {
    handleFilter,
    filteredList,
    filters
  } = FilterHandler(setFilteredList, userListUnFiltered);

  function setFilteredList() {
    setUserList(filteredList);
  }

  function handleSearch(e) {
    let val = e.target.value;
    if (val !== "") {
      let res = _.filter(userListUnFiltered, function (item) {
        return _.values(_.pick(item, 'lastName', 'username', 'email', 'userRoleId')).toString().toLocaleLowerCase().includes(val.toLocaleLowerCase());
      });
      setUserList(res);
    } else {
      setUserList(userListUnFiltered);
    }
  }

  function convertRoleIdToUserRole(roleId) {
    const userRole = userRoleList.find(role => role.id === roleId);
    if (userRole) {
      return userRole.name;
    }
    return ""; // Return a default value if the role ID is not found.
  }


  return (
    <div className="">
      <Container>
        <div>
          <div hidden={!logUser} className={"sc-back sa-cursor"} onClick={() => {
            setLogUser(null);
            setUserLogList([])
          }}><FeatherIcon className={"sc-back-icon"} icon={"arrow-left"}/>{logUser ? logUser.lastName : ""}</div>
          <span>
          <span className="sa-table-btn-mute sa-search-align sa-table-float-left">
                <input
                  className={'sa-table-search sa-table-search-point font-14'}
                  onChange={handleSearch}
                  autoComplete={"off"}
                  type="text"
                  placeholder={t("placeHolder.SEARCH")}
                  name="search"
                />
                <FeatherIcon className={"sa-search-icon"} icon={"search"} width={'16px'}/>
              </span>
              <select
                className="sa-filter"
                onChange={handleFilter}
                name={"status"}
              >
                <option value={""}>{filters && filters.status ? t("title.ALL") : t("title.STATUS")}</option>
                {statusList.map((status) => (
                  <option key={status} value={status}>{status}</option>
                ))}
              </select>
                            </span>
          {hasPermission("WRITE") &&
            <div className="pull-right">
              <span className={'sa-table-icon-flex'}>
                <button hidden={logUser}
                        onClick={() => setIsModalVisible(true)} className="sa-table-btn-secondary sa-table-float-right">
                  <FeatherIcon
                    icon={"plus"} className={"sa-table-icon-size"}/> <span
                  className={"sa-table-icon"}>{t("button.ADD")}</span>
                </button>
              </span>
            </div>
          }

          <div id="main3" onScroll={bottomReached} className="sa-table-container">
            <table hidden={logUser} className="table table-borderless sa-table-width">
              <thead>
              <tr>
                <th className={"sa-table-head-sticky"}>{t("title.NAME")}</th>
                <th className={"sa-table-head-sticky"}>{t("title.USERNAME")}</th>
                <th className={"sa-table-head-sticky"}>{t("title.EMAIL")}</th>
                <th className={"sa-table-head-sticky"}>{t("title.ROLE")}</th>
                <th className={"sa-table-head-sticky"}>{t("title.STATUS")}</th>
                {hasPermission("WRITE") &&
                  <th className="sa-table-item-align sa-table-head-sticky"></th>
                }
              </tr>
              </thead>
              <tbody>
              {userList.map((user, index) => (
                <tr key={index}>
                  <td className={'sa-table-data'}>{toCapsFirst(user.lastName)}</td>
                  <td className={'sa-table-data'}>{user.username}</td>
                  <td className={'sa-table-data'}>{user.email}</td>
                  <td className={'sa-table-data'}>{convertRoleIdToUserRole(user.userRoleId)}</td>
                  <td className={'sa-table-data'}>
                    {user.status === "INACTIVE" ? <div className="table-status-inactive">{user.status}</div> :
                      <div className="table-status-active">{user.status}</div>}
                  </td>
                  {hasPermission("WRITE") &&
                    <td className="sa-table-item-align">
                      <div className={'sa-table-action-flex'}>
                        <div className="sa-table-actions sa-action-m" data-toggle="tooltip" title={t("title.ALERT")}>
                          <AlertUserSetting userId={user.id}/></div>
                        <div className="sa-table-actions sa-action-m" data-toggle="tooltip" title={t("title.RESEND")}>
                          <FeatherIcon
                            onClick={() => resendVerification(user)} className={"table-action"}
                            icon={"upload"}/></div>
                        <div className="sa-table-actions sa-action-m" data-toggle="tooltip" title={"button.LOGS"}>
                          <FeatherIcon onClick={() => {
                            setLogUser(user);
                            setPage(1)
                          }} className={"table-action"}
                                       icon={"clock"}/>
                        </div>
                        {/*<div className="table-actions" data-toggle="tooltip" title="Alerts"><FeatherIcon className={"table-action"}*/}
                        {/*                                                                                 icon={"bell"}/></div>*/}
                        <div className="sa-table-actions sa-action-m" data-toggle="tooltip"
                             title={user.userRoleId >= 10000 ? "Edit" : "Can't edit this user!"}><FeatherIcon
                          onClick={() => {
                            setIsUpdateAvailable(true);
                            setIsModalVisible(true);
                            user.userRoleId >= 10000 && setIsModalVisible(true);
                            Object.keys(errors).forEach(k => errors[k] = "");
                            values.lastName = user.lastName;
                            values.mobile = user.mobile;
                            values.language = user.language;
                            values.email = user.email;
                            values.status = user.status;
                            values.id = user.id;
                            values.index = index;
                            setFormErr("");
                          }} className={"table-action " + (user.userRoleId >= 10000 ? "" : "disabled-action")}
                          icon={"edit"}/></div>
                        <div className="sa-table-actions sa-action-m" data-toggle="tooltip"
                             title={user.userRoleId >= 10000 ? "Delete" : "Can't delete this user!"}><FeatherIcon
                          onClick={() => handleDeletePlotUser(user)}
                          className={(user.userRoleId >= 10000 ? "text-red " : "disabled-action")} icon={"trash-2"}/>
                        </div>
                      </div>
                    </td>
                  }
                </tr>
              ))}
              </tbody>
            </table>
            {userList.length === 0 && (
              <div className={"empty-results"}>
                <FeatherIcon icon="info"/>
                <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_USERS_PLEASE_ADD")}</div>
              </div>)
            }
            <table hidden={!logUser} className="table table-borderless table-user">
              <thead>
              <tr>
                <th className="tw-190" scope="col">{t("title.ACTION")}</th>
                <th className="tw-190" scope="col">{t("title.ACTION_AT")}</th>
              </tr>
              </thead>
              <tbody>
              {userLogList && userLogList.length > 0 && userLogList.map((user, index) => (
                <tr key={index}>
                  <td>{formatDisplayEnumValue(user.action)}</td>
                  <td>{user.actionAt}</td>
                </tr>
              ))}
              </tbody>
            </table>
            {logUser && userLogList.length === 0 && (
              <div className={"empty-results"}>
                <FeatherIcon icon="info"/>
                <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_USERS_LOGS")}</div>
              </div>)
            }
          </div>
        </div>
      </Container>
      <div onClick={formClick} className={"sa-popup-bg " + (!isModelVisible && 'hide')}>
        <div className="sa-popup">
          <form onSubmit={handleSubmit} noValidate className={'sa-modal-box-style'}>
            <div className="sa-popup-header">
              <span
                className={'sa-model-heading'}>{!isUpdateAvailable ? t("title.ADD_NEW_USER") : t("title.EDIT_USER")}</span>
              <div className="sa-popup-close-icon" onClick={resetFormData}>
                <FeatherIcon
                  className={"sa-modal-close-icon"} icon={"x"}/>
              </div>
            </div>
            <div hidden={isUpdateAvailable}>
              {InformativeMSG(t("info.USER_EMAIL_REGISTRATION_MESSAGE"))}
            </div>
            <div className="sa-popup-content">
              <div className="col-md-6">
                <div className="form-group m-b-16">
                  <label htmlFor="email">{t("title.EMAIL")}</label>
                  <input onBlur={handleOnBlur} onChange={onChangeEmail}
                         autoComplete={"off"}
                         value={values.email || ''} type="text"
                         className={`form-control ${errors.email && "warning-input"}`}
                         name="email"
                         placeholder={t("placeHolder.ENTER_EMAIL_ADDRESS")}/>
                  <ul hidden={suggestion.length === 0}
                      style={{
                        height: "150px",
                        overflowY: "auto",
                        fontSize: 10,
                        zIndex: 999,
                        position: "absolute",
                        width: "95%"
                      }}
                      className="list-group">{suggestion && suggestion.map(user => (
                    <li name={"suggestion"} id={"word"} style={{overflowWrap: "break-word"}} className="list-group-item"
                        value={user.id}
                        onClick={() => onSelectEmail(user)} key={user.id}>{user.email}</li>))}
                  </ul>
                  {errors.email && (<p className="warning-input-msg">{errors.email}</p>)}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group m-b-16">
                  <label htmlFor="email">{t("title.NAME")}</label>
                  <input disabled={selectedUser} onBlur={handleOnBlur} onChange={handleChange}
                         value={values.lastName || ''} type="text"
                         className={`form-control ${errors.lastName && "warning-input"}`}
                         name="lastName"
                         placeholder={t("placeHolder.ENTER_USER'S_NAME")}/>
                  {errors.lastName && (
                    <p className="warning-input-msg ">{errors.lastName}</p>)}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group m-b-16">
                  <label htmlFor="email">{t("title.PHONE_NUMBER")}</label>
                  <input disabled={selectedUser} onBlur={handleOnBlur} onChange={handleChange}
                         value={values.mobile || ''} type="text"
                         className={`form-control ${errors.mobile && "warning-input"}`}
                         name="mobile"
                         placeholder={t("placeHolder.ENTER_USER'S_MOBILE_NO")}/>
                  {errors.mobile && (<p className="warning-input-msg">{errors.mobile}</p>)}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group m-b-16">
                  <label htmlFor="exampleFormControlSelect1">{t("title.LANGUAGE")}</label>
                  <select disabled={selectedUser} onBlur={handleOnBlur} onChange={handleChange}
                          value={values.language || ''}
                          className={`form-control ${errors.language && "warning-input"}`}
                          id="exampleFormControlSelect1" name="language">
                    <option hidden value={""}>{t("placeHolder.SELECT_USER_LANGUAGE")}</option>
                    <option value="TAMIL">{t("title.TAMIL")}</option>
                    <option value="SINHALA">{t("title.SINHALA")}</option>
                    <option value="ENGLISH">{t("title.ENGLISH")}</option>
                  </select>
                  {errors.language && (<p className="warning-input-msg">{errors.language}</p>)}
                </div>
              </div>
              {!isUpdateAvailable ?
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">{t("title.ROLE")}{t("title.ROLE")}</label>
                    <select disabled={selectedUser} onBlur={handleOnBlur} onChange={handleChange}
                            value={values.userRoleId || ''}
                            className={`form-control ${errors.userRoleId && "warning-input"}`}
                            id="exampleFormControlSelect1" name="userRoleId">
                      <option hidden value={""}>{t("placeHolder.SELECT_USER_ROLE")}</option>
                      <option value="10000">{t("placeHolder.PLOT_USER")}</option>
                    </select>
                    {errors.userRoleId && (
                      <p className="warning-input-msg">{errors.userRoleId}</p>)}
                  </div>
                </div>
                :
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">{t("title.STATUS")}</label>
                    <select onBlur={handleOnBlur} onChange={handleChange}
                            value={values.status || ''}
                            className={`form-control ${errors.status && "warning-input"}`}
                            id="exampleFormControlSelect1" name="status">
                      <option hidden value={""}>{t("placeHolder.SELECT_USER_STATUS")}</option>
                      <option value="ACTIVE">{t("title.ACTIVE")}</option>
                      <option value="INACTIVE">{t("title.INACTIVE")}</option>
                    </select>
                    {errors.status && (
                      <p className="warning-input-msg">{errors.status}</p>)}
                  </div>
                </div>
              }
            </div>

            {!isUpdateAvailable && !selectedUser &&
              <div className={"advance-m table-container-toggle mb-2 " + (isTable ? "" : "table-toggle-hide")}>
                <hr className="table-toggle-line"/>
                <div className="table-toggle-heading">{t("title.ADVANCE")}</div>
                <div onClick={() => {
                  setTable(!isTable)
                  setValue({isTable: !isTable})
                }} className="table-dropdown-icon"><i
                  className={"fa " + (isTable ? "fa-angle-up" : "fa-angle-down")}/></div>
              </div>}

            <div className="sa-popup-content" hidden={!isTable}>
              <div className="col-md-6">
                <div className="form-group m-b-16">
                  <label htmlFor="email">{t("title.USERNAME")}</label>
                  <input type="text" value={values.username || ''} onChange={handleChange}
                         onBlur={handleOnBlur} autoComplete="off"
                         className={errors.username ? "form-control warning-input" : "form-control"}
                         name="username" placeholder={t('placeHolder.ENTER_YOUR_USERNAME')}/>
                  {errors.username && (<p className="warning-input-msg">{errors.username}</p>)}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group m-b-6">
                  <label htmlFor="pwd">{t("title.PASSWORD")}</label>
                  <input type={!showText ? "password" : "text"} value={values.password || ''}
                         onChange={handleChange} autoComplete="off"
                         onBlur={handleOnBlur}
                         className={errors.password ? "form-control warning-input" : "form-control"}
                         name="password" placeholder={t("placeHolder.ENTER_YOUR_PASSWORD")}/>
                  <span className="field-icon" onClick={() => setShowText(!showText)}><FeatherIcon
                    className="toggle-password" icon={showText ? "eye-off" : "eye"}/></span>
                  {errors.password && (<p className="warning-input-msg">{errors.password}</p>)}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group m-b-6">
                  <label htmlFor="pwd">{t("title.CONFIRM_PASSWORD")}</label>
                  <input type={!showText ? "password" : "text"} value={values.confirmPassword || ''}
                         onBlur={handleOnBlur} autoComplete="off"
                         onChange={handleChange}
                         className={errors.confirmPassword ? "form-control warning-input" : "form-control"}
                         name="confirmPassword" placeholder={t("placeHolder.ENTER_YOUR_PASSWORD")}/>
                  <span className="field-icon" onClick={() => setShowText(!showText)}><FeatherIcon
                    className="toggle-password" icon={showText ? "eye-off" : "eye"}/></span>
                  {errors.confirmPassword && (<p className="warning-input-msg">{errors.confirmPassword}</p>)}
                  {errors.message && (<div className={"login-warning-msg"}>{errors.message}</div>)}
                </div>
              </div>
            </div>


            {formErr && (<div className={"login-warning-msg"}>{formErr}</div>)}
            <div className="sa-popup-btn">
              <button type={"button"} className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style"
                      onClick={resetFormData}>{t("button.CANCEL")}
              </button>
              <button className="sa-popup-secondary-btn-style">
                {!isUpdateAvailable ? t("button.ADD") : t("button.UPDATE")}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
};


export default PlotUser
