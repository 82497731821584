import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getSelectedSensor} from "../../../actions/sensor";
import FeatherIcon from "feather-icons-react";
import {TOGGLE} from "../../../utils/Enum";
import {
  batteryReading,
  formatDisplayValue,
  getBatteryIcon,
  getIcon,
  getWifiIcon,
  isDeviceOnline
} from "../../../utils/Utils";
import DeviceBatteryReport from "../plot/sensor-item-battery-report";
import * as _ from "underscore";
import axios from "axios";
import {getDisplayName, isEmpty, toBrowserTimeZone} from "../../../shared/utils/utils";

const SensorItem = (props) => {
  const [batteryLevel, setBatteryLevel] = useState(0);
  const dispatch = useDispatch();
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [device, setDevice] = useState(null);
  const [filteredSensors, setFilteredSensors] = useState([]);
  const [powerSupply, setPowerSupply] = useState([0]);
  const [signalStrength, setSignalStrength] = useState([0]);

  const selectedSensor = useSelector(state => {
    return state.sensor.selectedSensor;
  });
  const selectedKit = useSelector(state => {
    return state.plotList.selectedKit;
  });

  const toggle = useSelector(state => {
    return state.setting.toggle
  });


  function isOnline(time) {
    if (isDeviceOnline(time, props.kit.interval)) {
      return (<div className="heading">
        <div className="status-circle-green"/>
        Online
      </div>);
    } else {
      return (<div className="heading">
        <div className="status-circle-black"/>
        Offline
      </div>);
    }

  }

  function showGraphReport() {
    setSelectedDevice(true);
  }

  function hasAlert(sensor) {
    return sensor.alert && !isEmpty(selectedKit.alerts);
    // return true;
  }

  function sensorClick(index) {
    if (toggle === TOGGLE.MAP) {
      dispatch(getSelectedSensor(index));
    }
  }

  useEffect(() => {
    if (!device || !device.sensors) {
      return;
    }

    let bSensor = device.sensors.find(e => e.code === "B");
    let psSensor = device.sensors.find(e => e.code === "PS");
    let ssSensor = device.sensors.find(e => e.code === "SS");
    if (bSensor && device.battery) {
      setBatteryLevel(batteryReading(+bSensor.value, device.battery.low, device.battery.high));
    }
    if (psSensor) {
      setPowerSupply(+psSensor.value)
    }

    if (ssSensor) {
      setSignalStrength(+ssSensor.value)
    }
  }, [device]);

  useEffect(() => {
    if (!props.cluster) {
      return;
    }

    axios.get(process.env.REACT_APP_HOST + `/core/device/` + props.cluster[0].deviceId)
      .then(res => {
        setDevice(res.data.content);
      });

    let sensors = _.filter(props.cluster, function (item) {
      return item.code !== "WI" && item.code !== "IT" && item.code !== "SS";
    });
    setFilteredSensors(sensors);
  }, [props.cluster]);

  return (
    <div className={window.innerWidth > 620 ? "sensor-width" : "mo-plot-list-card"}
         onClick={() => sensorClick(props.index)}>
      <div className="single-card">
        <div
          className={"sensor-kit-main single-card-header " + (props.index === selectedSensor ? 'card-top-border' : '')}>
          <div className="row sa-table-flex">
            <div className="card-header-text-2">
              <div
                className="heading  limit-chara-actuator-sensor">{props.cluster[0].clusterName ? props.cluster[0].clusterName : 'Sensor ' + (props.index + 1)}</div>
            </div>
            <div className="text-center sensor-kit">
              <div className="card-header-status">
                {isOnline(toBrowserTimeZone(props.cluster[0].timeString))}
                <div className="sub-text">{toBrowserTimeZone(props.cluster[0].timeString)}</div>
              </div>
            </div>
            <div className="m-r-6">
              {props.kit.battery && !isEmpty(props.kit.battery) && (
                <div onClick={showGraphReport} className="card-header-actions pull-right">
                  {getBatteryIcon(batteryLevel)} {batteryLevel}%
                </div>
              )}
              {signalStrength && !isEmpty(signalStrength) && (
                <div className="card-header-actions pull-right" style={{marginRight: '10px'}}>
                  {getWifiIcon(signalStrength)}
                </div>
              )}
              {powerSupply && (powerSupply === 1) && (
                <div className="card-header-actions pull-right" style={{marginRight: '10px'}}>
                  <FeatherIcon icon="battery-charging"/>
                </div>
              )}

            </div>
          </div>
        </div>
        <div className="single-card-content">
          <div className="row p-1">
            {filteredSensors.map((sensor, index) => (
              <div className="col-6" key={'sensor' + index}>
                <div className={hasAlert(sensor) ? "sensor-details warning" : "sensor-details"}>
                  {getIcon(sensor.code)}
                  <div className="sensor-data sensor-data-p">
                    <div
                      className={isDeviceOnline(toBrowserTimeZone(props.cluster[0].timeString), props.kit.interval) ? "sensor-value limit-sensorName-sa" : "sensor-value limit-sensorName-sa opacity-30"}
                      title={formatDisplayValue(sensor.displayValue)}
                    >
                      {formatDisplayValue(sensor.displayValue)}
                    </div>
                    <div className="sensor-Name limit-sensorName-sa"
                         title={getDisplayName(sensor, props.kit.metaData)}>{getDisplayName(sensor, props.kit.metaData)}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {selectedDevice &&
        <DeviceBatteryReport device={props} onClose={() => setSelectedDevice(null)}/>
      }
    </div>
  );
};


export default SensorItem
