import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import {hasPermission} from "../../../utils/Authentication";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import FormHandler from "../../../shared/utils/FormHandler";
import {validateKitForm} from "../../../utils/FormValidationRules";
import {toast} from "react-toastify";
import {editAddIcon} from "../../../utils/Utils";
import FeatherIcon from "feather-icons-react";
import {toggleLoader,} from "../../../shared/actions/setting";
import {useTranslation} from "react-i18next";
import {isEmpty} from "../../../shared/utils/utils";
import {getUserId} from "../../../shared/utils/SharedAuthentication";
import ValveSettingRow from "./valve-settings-row";

const ValveSetting = (props) => {
  const {t, i18n} = useTranslation();
  const [irrigation, setIrrigation] = useState({});
  const [isEditValve, setIsEditValve] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [loadValves, setLoadValves] = useState(true);
  const [isAddRow, setIsAddRow] = useState(false);
  const [unAssignedActuators, setUnAssignedActuators] = useState([]);
  const [onOffListOptions, setOnOffListOptions] = useState([]);
  const [activeIrrigation, setActiveIrrigation] = useState([]);
  const [creatingIrrigation, setCreatingIrrigation] = useState([]);
  const [updatingIrrigationList, setUpdatingIrrigationList] = useState([]);
  const [showAssignCancel, setShowAssignCancel] = useState(false)
  const [isShowAddButton, setIsShowAddButton] = useState(true)
  const [actuator, setActuator] = useState([])
  const [deletedIrrigations, setDeletedIrrigations] = useState([])
  const [irrigationHistory, setIrrigationHistory] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [isAddDisabled, setIsAddDisabled] = useState(false)
  var irrigations = []

  const {
    values,
    errors,
    handleChange,
    setValue,
    handleSubmit,
    initForm,
    handleOnBlur,
  } = FormHandler(submitKit, validateKitForm);

  const plotList = useSelector((state) => {
    return state.plotList.plotList;
  });
  let sectorName = props.type === "FARM" ? "farm" : plotList.filter((plot) => plot.id == props.plotId)[0].name;


  function updateData(value, isUpdatingList, index) {
    value = {...value, "type": props.type};
    let temp = isUpdatingList ? updatingIrrigationList : creatingIrrigation;
    const valveCodesBefore = updatingIrrigationList.map(obj => obj.valveCode);

    let filterDelete = deletedIrrigations.filter((item) => value.valveCode === item[0].valveCode);
    let filterUpdate = updatingIrrigationList.filter((item) => value.valveCode === item.valveCode);
    let found = false

    if (filterUpdate.length === 1 && filterDelete.length === 1) {
      found = filterDelete[0][0].id === filterUpdate[0].id
    }

    // setDeletedIrrigations(deletedIrrigations.filter((item) => value.valveCode !== item[0].valveCode));

    if (temp.length === 0) {
      temp.push(value);
    } else {
      temp[index] = {...value};
    }

    if (isUpdatingList) {
      setUpdatingIrrigationList([...temp]);
    } else {
      setCreatingIrrigation([...temp]);
    }

    const valveCodesAfter = updatingIrrigationList.map(obj => obj.valveCode);
    if (found) {
      const filteredCode = valveCodesBefore.filter(code => !valveCodesAfter.includes(code));
      if (filteredCode.length > 0) {
        filterDelete[0][0].valveCode = filteredCode[0]
      }
      console.log('FILTERED...', valveCodesBefore, valveCodesAfter)
      if (!isUpdatingList) {
        setDeletedIrrigations(deletedIrrigations.filter((item) => value.valveCode !== item[0].valveCode));
      }
    } else {
      setDeletedIrrigations(deletedIrrigations.filter((item) => value.valveCode !== item[0].valveCode));
    }
  }


  useEffect(() => {
    if (unAssignedActuators.length === 0 || actuator.length > 0) {
      return
    }

    let tempUpdate = []
    updatingIrrigationList.map((item) => tempUpdate.push(item.valveCode))

    let tempAct = []
    unAssignedActuators.filter((item) => item.assigned).filter((e) => !tempUpdate.includes(e.code)).map((act) => tempAct.push(act))

    setActuator(unAssignedActuators.filter((e) => !tempAct.includes(e)))
  }, [updatingIrrigationList, unAssignedActuators])

  useEffect(() => {
    setActuator([])
    setUnAssignedActuators([])
  }, [sectorName])

  useEffect(() => {
    if (!props.farmId) {
      return
    }

    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + props.farmId + '/valve')
      .then(response => {
        setActiveIrrigation(response.data.content)

      })
      .catch(error => console.error(`Error ${error}`))
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, [props.farmId]);

  function handleAddRow() {
    for (let irr of creatingIrrigation) {

      if (!irr.valveCode) {
        setError("Valve code is required");
        return
      }
    }
    setIsAddRow(true)
    setShowAssignCancel(true)
    setError(null)


    let temp = creatingIrrigation;

    temp.push({valveCode: "", priorityLevel: "", onList: [], offList: []});

    setIsEditValve(true);

    setCreatingIrrigation([...temp]);
    setShowAssignCancel(true)

  }


  function onCancel() {
    setCreatingIrrigation([]);
    setDeletedIrrigations([]);
    setUpdatingIrrigationList([...irrigationHistory])
    setShowAssignCancel(false)
    setError(null)
    setRefresh(!refresh)

  }

  function deleteIrrigation(index, isUpdating, item) {

    if (item.valveCode === "") {
      let irr = creatingIrrigation.filter(e => e.valveCode !== "")
      setCreatingIrrigation([...irr])
      setError(null)
      return false;
    }

    let data = activeIrrigation.filter((val) => val.code === item.valveCode)[0];
    if (data && data.openInServer) {
      setError("Irrigation is active, can not be deleted");
      return true;
    } else {
      if (isUpdating) {
        let temp = [...updatingIrrigationList]
        deletedIrrigations.push(temp.splice(index, 1))
        setDeletedIrrigations([...deletedIrrigations])

      } else {
        let temp = [...creatingIrrigation]
        deletedIrrigations.push(temp.splice(index, 1))
        setDeletedIrrigations([...deletedIrrigations])
        setCreatingIrrigation(temp);
      }
    }

    setError(null)
    return false;

  }

  // function removeExtraCreatingIrrigationRow() {
  //   let temp = creatingIrrigation
  //   for (let i = 0; i < temp.length; i++) {
  //     let item = temp[i]
  //     if (item.valveCode === "") {
  //       temp.splice(i, 1)
  //     }

  //   }
  //   setCreatingIrrigation(temp)
  // }

  function getValveCodeList(irrigations) {
    let temp = []
    for (let i = 0; i < irrigations.length; i++) {
      let item = irrigations[i]
      // if( item.valveCode!=="" ){
      temp.push(item[0].valveCode)
      // }

    }
    return temp
  }

  function removeDeletedItemsFromIrrigationList(irrigations) {

    let valveCodeList = getValveCodeList(deletedIrrigations);
    let valveCodeList2 = []
    creatingIrrigation.map((item) => valveCodeList2.push(item.valveCode))
    let temp = []
    for (let i = 0; i < irrigations.length; i++) {
      let item = irrigations[i]

      if (!valveCodeList.includes(item.valveCode) && !valveCodeList2.includes(item.valveCode)) {
        temp.push(item)

      }
    }


    temp = temp.concat(creatingIrrigation);


    return temp

  }

  function updateBulkIrrigation() {

    for (let irr of updatingIrrigationList) {
      if (!irr.valveCode) {
        setError("Valve code is required");
        return
      }
    }


    axios
      .put(
        process.env.REACT_APP_HOST + `/user/` + getUserId() + `/reference/` + props.plotId + "/bulk-irrigation",
        removeDeletedItemsFromIrrigationList(updatingIrrigationList)
      )
      .then((res) => {
        setDeletedIrrigations([])
        if (res.data.content.length === 0) {
          setIsEditValve(false);
        }
        setIrrigation(values);
        setLoadValves(true);
        setShowAssignCancel(false)
        //   setUpdatingIrrigationList(res.data.content);
        setUpdatingIrrigationList([]);

        setUpdatingIrrigationList(res.data.content);

        console.log("show all", res.data.content)
        setCreatingIrrigation([])
        fetchUnAssignedActuators()

        if (updatingIrrigationList.length !== 0) {
          toast.success(t("successMsg.VALVE_CODE_UPDATED_SUCCESSFULLY"));
        } else {
          toast.success(t("successMsg.VALVE_CODE_ADDED_SUCCESSFULLY"));
        }
        setError(null);
        // setRefresh(!refresh)
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          setError(error.response.data.message);
          // toast.error(t("errorMsg.SOMETHING_WENT_WRONG"));
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }

  function submitKit() {
    dispatch(toggleLoader(true));
    if (!isEmpty(irrigation)) {
      axios
        .put(
          process.env.REACT_APP_HOST + `/user/` + getUserId() + `/reference/` + props.plotId + "/irrigation",
          updatingIrrigationList
        )
        .then((res) => {
          setIsEditValve(false);
          setIrrigation(values);
          setLoadValves(true);
          setUpdatingIrrigationList([...updatingIrrigationList, res.content])
          setIrrigationHistory([...updatingIrrigationList, res.content])

          toast.success(t("successMsg.VALVE_CODE_UPDATED_SUCCESSFULLY"));
          setError(null);
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            setError(error.response.data.message);
            // toast.error(t("errorMsg.SOMETHING_WENT_WRONG"));
          }
        })
        .finally(() => {
          dispatch(toggleLoader(false));
        });
    } else {
      axios
        .post(
          process.env.REACT_APP_HOST + `/user/` + getUserId() + `/reference/` + props.plotId + "/irrigation",
          creatingIrrigation
        )
        .then((res) => {
          setIsEditValve(false);
          setIrrigation(values);
          setLoadValves(true);
          setUpdatingIrrigationList([...updatingIrrigationList, res.content])
          setIrrigationHistory([...updatingIrrigationList, res.content])
          // setCreatingIrrigation([])

          if (irrigation.valveCode) {
            toast.success(t("successMsg.VALVE_CODE_UPDATED_SUCCESSFULLY"));
          } else {
            toast.success(t("successMsg.VALVE_CODE_ADDED_SUCCESSFULLY"));
          }
          setError(null);
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            setError(error.response.data.message);
            // toast.error(t("errorMsg.SOMETHING_WENT_WRONG"));
          }
        })
        .finally(() => {
          dispatch(toggleLoader(false));
        });
    }
  }

  function editValve() {
    setCreatingIrrigation([
      {valveCode: "", priorityLevel: "", onList: [], offList: []},
    ]);

    setIsEditValve(!isEditValve);
    initForm(irrigation);
  }

  function cancelForm(event) {
    event.preventDefault();
    setIsEditValve(false);
    setError(null);
  }


  useEffect(() => {
    setShowAssignCancel(false)
    setIsEditValve(false)
    setError(null)
    setUpdatingIrrigationList([])
    setCreatingIrrigation([])

    dispatch(toggleLoader(true));
    axios
      .get(
        process.env.REACT_APP_HOST + `/user/` + getUserId() + `/reference/` + props.plotId + "/irrigation"
      )
      .then(function (response) {
        irrigations = [...response.data.content]


        setIrrigation(response.data.content ? response.data.content : {});

        setIrrigationHistory(response.data.content)
        setUpdatingIrrigationList(response.data.content);
        setUpdatingIrrigationList([]);
        setUpdatingIrrigationList(response.data.content);

        fetchUnAssignedActuators()
        if (response.data.content.length > 0) {
          setShowAssignCancel(false)
        }

        let temp = [];

        temp.push({valveCode: "", priorityLevel: "", onList: [], offList: []});
        if (!(response.data.content.length > 0)) {
          if (!creatingIrrigation.length > 0) {
            setCreatingIrrigation(temp);
          }

        } else {
          setIsShowAddButton(true)
        }


        dispatch(toggleLoader(false));
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
        }
        dispatch(toggleLoader(false));
      })
      .finally();
  }, [props.plotId, refresh]);


  async function handleChangeAsync(e) {
    handleChange(e);
  }

  function fetchUnAssignedActuators() {
    axios
      .get(
        process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + props.farmId + "/valve?category=ALL"
      )
      .then(function (response) {
        let data = response.data.content
        setUnAssignedActuators(data);

        let options = [];

        let filteredActuators = data.filter(
          (actuator) => actuator.type !== "BRANCH"
        )

        if (!filteredActuators.length > 0) {
          setError("Actuators unavailable . Please configure new Actuators to proceed")
          setIsAddDisabled(true)
        }
        data.filter(
          (actuator) => actuator.type !== "BRANCH"
        ).map((act) => {
          options.push({
            label: act.code + "( " + sectorName + " )",
            value: act.code + "( " + sectorName + " )"
          })
        })

        irrigations.map((irrigation1) => {
          options.push({
            label: irrigation1.valveCode + "( " + sectorName + " )",
            value: irrigation1.valveCode + "( " + sectorName + " )"
          })
        })

        setOnOffListOptions([...options])
        dispatch(toggleLoader(false));
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
        }
        dispatch(toggleLoader(false));
      })
  }


  return (
    <Container>
      {!isEditValve && (!updatingIrrigationList.length > 0) ? (
        <div className={"empty-results"}>
          <FeatherIcon icon="info"/>
          <div className={"empty-results-text"}>
            {error ? error : t("emptyMsg.VALUE_NOT_CONFIGURED")}
          </div>
          <div className="pull-right hide-768 m-r_-10">
            <button
              className={"white-background"}
              disabled={isAddDisabled}
              onClick={() => {
                setShowAssignCancel(true)
                setIsEditValve(true)
                setIsAddRow(true)
                // handleAddRow()

              }}

            >
              {editAddIcon(
                t("button.ADD")
              )}
            </button>

          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit} noValidate>


          <div className={"border-0"}>
            <div className={"flex-row"}>
              <div className={"max-w320 value-settings-select"}>
                <div className={"farm-info-title"}>{t("title.ACTUATOR")}</div>
              </div>

              <div className={"max-w320 value-settings-select"}>
                <div className={"farm-info-title"}>
                  {t("title.PRIORITY_LEVEL")}
                </div>
              </div>

              <div className={"max-w320 value-settings-select"}>
                <div className={"farm-info-title"}>{t("title.ON_LIST")}</div>
              </div>

              <div className={"max-w320 value-settings-select"}>
                <div className={"farm-info-title"}>{t("title.OFF_LIST")}</div>
              </div>
            </div>
          </div>
          <div>
            {updatingIrrigationList.map((item, index) => {
              return (
                <ValveSettingRow
                  sectorType={props.type}
                  updatingIrrigation={updatingIrrigationList}
                  creatingIrrigation={creatingIrrigation}
                  showAsignCancel={showAssignCancel}
                  error={error}
                  setError={setError}
                  deletedIrrigation={deletedIrrigations}

                  deleteIrrigation={deleteIrrigation}
                  isUpdatingList={true}
                  actuator={actuator}

                  plotId={props.plotId}
                  item={item}
                  plotName={sectorName}
                  data={updatingIrrigationList}
                  setData={setUpdatingIrrigationList}
                  updateData={updateData}
                  onOffListOptions={onOffListOptions}
                  key={index}
                  index={index}
                  setUnAssignedActuators={(value) => {
                    setUnAssignedActuators(value);
                  }}
                  unAssingedActuators={unAssignedActuators}
                />
              )
            })}
            {creatingIrrigation.map((item, index) => (
              <ValveSettingRow
                sectorType={props.type}

                updatingIrrigation={updatingIrrigationList}
                creatingIrrigation={creatingIrrigation}
                deletedIrrigation={deletedIrrigations}
                actuator={actuator}

                deleteIrrigation={deleteIrrigation}
                showAsignCancel={showAssignCancel}
                error={error}
                setError={setError}
                isUpdatingList={false}
                plotId={props.plotId}
                plotName={sectorName}
                data={creatingIrrigation}
                setData={setCreatingIrrigation}
                item={item}
                setIsAddRow={(value) => {
                  setIsAddRow(value);
                }}
                updateData={updateData}
                handleAddRow={handleAddRow}
                onOffListOptions={onOffListOptions}
                key={index}
                index={index}
                setUnAssignedActuators={(value) => {
                  setUnAssignedActuators(value);
                }}
                unAssingedActuators={unAssignedActuators}
              />
            ))}

          </div>

          {error && <div className={"login-warning-msg"}>{error}</div>}
          {hasPermission("WRITE") &&
            <div className={"max-w320 value-settings-select"}>

              <div hidden={!showAssignCancel}
                   onClick={() => handleAddRow()} style={{marginTop: 10, marginRight: 40, display: ''}}
                   id={"kitSettingAddOrEdit"} className={'white-background'}>
          <span className={'btn  edit-btn-color  w-235p'}><FeatherIcon
            className={"text-gray icon-setting"} icon="plus"/><span
            className={'hide-425'}>Add New Actuator</span></span>
              </div>
            </div>
          }

        </form>
      )}


      {(hasPermission("WRITE") && !showAssignCancel && updatingIrrigationList.length > 0) &&
        <div style={{marginTop: 80, marginRight: 20}} className="pull-right hide-768 m-r_-10">
          <button
            onClick={() => {
              // setIsEditValve(true)
              setShowAssignCancel(true)

            }} id={"kitSettingAddOrEdit"} className={'white-background'}

            // hidden={!showAsignCancel}
          >
            {editAddIcon(t("button.EDIT"))}
          </button>
        </div>}
      {hasPermission("WRITE") && (


        <div style={{marginTop: 200}}
             hidden={!showAssignCancel}
             className="sa-popup-btn"
        >
          <button
            onClick={() => {
              setIsEditValve(!isEditValve)
              onCancel()
            }}
            type={"button"}
            className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style"
          >
            {t("button.CANCEL")}
          </button>
          <button
            onClick={() => {
              dispatch(toggleLoader(true));
              console.log(creatingIrrigation, deletedIrrigations);
              // if (creatingIrrigation.length !== 0 && deletedIrrigations.length===0) {
              //   createBulkIrrigation();
              // } else {
              updateBulkIrrigation();
              // }
              dispatch(toggleLoader(false));
            }}
            className="sa-popup-secondary-btn-style"
          >
            {t("button.ASSIGN")}
          </button>
        </div>)}
    </Container>
  );
};

export default ValveSetting;