import {browserName} from "react-device-detect";
import * as moment from "moment-timezone";
import limitexceed from '../images/icons/basic-detail-icon.svg';
import FeatherIcon from "feather-icons-react";

export function isEmpty(obj) {
  if (!obj) {
    return true;
  }
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

export function isFunction(functionToCheck) {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

export function timeDurationMinutes(startTime, endTime) {
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);
  return (endDate - startDate);
}

export function toServerTimeZone(value) {
  const timeZone = moment.tz.guess();
  const ist = moment.tz(value, timeZone);
  ist.tz("Asia/Colombo");
  return ist.format('YYYY-MM-DD HH:mm:ss');
}

export function toServerTimeZone24(value) {
  const timeZone = moment.tz.guess();
  const originalTime = moment.tz(value, 'HH:mm', timeZone);
  const targetTime = originalTime.clone().tz("Asia/Colombo");
  return targetTime.format('HH:mm');
}

export function toBrowserTimeZone24(value) {
  const timeZone = moment.tz.guess();
  const originalTime = moment.tz(value, 'HH:mm', "Asia/Colombo");
  const targetTime = originalTime.clone().tz(timeZone);
  return targetTime.format('HH:mm');
}

//return date in yyyy-mm-dd
export function dateFormat(date) {
  if (!date) return;
  date = toServerTimeZone(date);

  let dateObj = browserName === 'Safari' ? new Date(date.replace(/-/g, "/")) : new Date(date);
  return new Date(dateObj.getTime() - (dateObj.getTimezoneOffset() * 60000)).toISOString().slice(0, 10);
}

export function dateAndTimeFormat(date) {

  if (!date) return;
  // let date1 = new Date(date);
  let date1 = browserName === 'Safari' ? new Date(date.replace(/-/g, "/")) : new Date(date);
  return (new Date(date1.getTime() - (date1.getTimezoneOffset() * 60000)).toISOString().slice(0, 10)) + " " +
    (new Date(date1.getTime() - (date1.getTimezoneOffset() * 60000)).toISOString().slice(11, 16));
}

export function getDisplayName(property, metaData) {
  if (!property) {
    return;
  }
  if (metaData && metaData.propertyNameMap && metaData.propertyNameMap !== "") {
    let mapping = JSON.parse(metaData.propertyNameMap);

    if (mapping[property.number]) {
      return mapping[property.number];
    }

  }

  return property.displayName === "Temp" ? "Temperature" : property.displayName;
}

export function toBrowserTimeZone(value) {

  var timeZone = moment.tz.guess();

  const ist = moment.tz(value, 'Asia/Colombo');
  ist.tz(timeZone);
  return ist.format('YYYY-MM-DD HH:mm:ss');
}

export function timeDurationForGraph(Time) {
  const diffDays = Math.floor(Time / 86400000); // days
  const diffHrs = Math.floor((Time % 86400000) / 3600000); // hours
  const diffMins = Math.round(((Time % 86400000) % 3600000) / 60000); // minutes
  if (diffDays === 0) {
    if (diffHrs === 0) {
      return (diffMins + " minutes");
    }
    return (diffHrs + " hours, " + diffMins + " minutes");
  }
  return (diffDays + " days, " + diffHrs + " hours, " + diffMins + " minutes");
}

export function toCapsFirst(name) {
  if (name) {
    return name.charAt(0).toUpperCase() + name.slice(1).toLocaleLowerCase();
  } else {
    return "";
  }
}

export function formatDisplayEnumValue(value) {
  if (!value) return "";
  return toCapsFirst(value.toString().replace(/_/g, " "));
}

export function timeSince(date) {
  date = toBrowserTimeZone(date);
  let sinceDate = new Date(date)
  let seconds = Math.floor((new Date() - sinceDate) / 1000);
  let sinceTime = null
  let min, hours
  if (seconds < 60) {
    return sinceTime = "few seconds ago"
  } else if (seconds < 3600) {
    min = Math.floor(seconds / 60)
    return sinceTime = min + " minutes ago"
  } else if (seconds < 86400) {
    hours = Math.floor(seconds / 3600)
    return sinceTime = hours + " hours ago"
  } else {
    return date
  }
}

export function getNotificationIcon(notificationName) {
  switch (notificationName) {
    case 'CASHFLOW':
    case "CASHFLOW_YIELD_UPDATES":
    case "CASHFLOW_INCOME_UPDATES":
    case "CASHFLOW_EXPENSE_UPDATES":
      return (
        <div className="float-left mt-2 ml-2 d-flex justify-content-center align-items-center image-container-toggle">
          <FeatherIcon className={"icon-in-notification icon-red"}
                       icon="dollar-sign"/></div>);
    case 'DAILY UPDATE':
    case "FARM_SCOUTING_REPORT_UPDATES":
    case "FARM_TASK_REPORT_UPDATES":
      return (
        <div className="float-left mt-2 ml-2 d-flex justify-content-center align-items-center image-container-toggle">
          <FeatherIcon className={"icon-in-notification"}
                       icon="check-square"/></div>);
    case 'LIMIT EXCEEDS':
      return (
        <div className="float-left mt-2 ml-2 d-flex justify-content-center align-items-center image-container-toggle">
          <img className={"icon-in-notification"}
               height={'30px'} width={'24px'}
               src={limitexceed} alt="limit exceed"/>
        </div>);
    case 'DEVICE OFFLINE':
      return (
        <div className="float-left mt-2 ml-2 d-flex justify-content-center align-items-center image-container-toggle">
          <FeatherIcon className={"icon-in-notification bg-light-green"}
                       icon={"anchor"}/></div>);
    case 'SENSOR ERROR':
      return (
        <div className="float-left mt-2 ml-2 d-flex justify-content-center align-items-center image-container-toggle">
          <FeatherIcon className={"icon-in-notification icon-red"}
                       icon={"radio"}/></div>);
    default:
      return (
        <div className="float-left mt-2 ml-2 d-flex justify-content-center align-items-center image-container-toggle">
          <FeatherIcon className={"icon-in-notification"}
                       icon={"radio"}/></div>);
  }
}
