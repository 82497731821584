export const getSelectedUserModule = (moduleList) => {
  return {
    type: "USER_MODULES",
    payload: moduleList
  };
};
export const getSelectedFarmModule = (farmModules) => {
  return {
    type: "FARM_MODULES",
    payload: farmModules
  };
};
export const getSelectedCorporate = (corporateId) => {
  return {
    type: "SELECTED_CORPORATE",
    payload: corporateId
  };
};
export const corporateChange = (corporateId) => {
  return {
    type: "CORPORATE_CHANGE",
    payload: corporateId
  };
};
export const corporateList = (corporateList) => {
  return {
    type: "CORPORATE_LIST",
    payload: corporateList
  };
};
export const getFilterCorporateList = (filterCorporateList) => {
  return {
    type: "FILTER_CORPORATE_LIST",
    payload: filterCorporateList
  };
};
