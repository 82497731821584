import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import Container from "react-bootstrap/Container";
import {changeToggle, changeView} from "../../shared/actions/setting";
import {TOGGLE, VIEW} from "../../utils/Enum";
import {option} from "react-timekeeper/lib/components/styles/time-dropdown";
import axios from "axios";
import {getSelectedCorporate} from "../../actions/module";
import {useTranslation} from "react-i18next";
import CCOperationsTab from "./cc-operation-tab";
import FeatherIcon from "feather-icons-react";
import {getRefCount, getUserId, isCorporateLevelUser} from "../../shared/utils/SharedAuthentication";


const OperationsHeader = (props) => {
  const {t, i18n} = useTranslation();

  const dispatch = useDispatch();
  const [corporateData, setCorporateData] = useState([]);

  useEffect(() => {
    dispatch(changeView(VIEW.XINDICATE_ALL));
    dispatch(changeToggle(TOGGLE.LIST));

  }, []);

  useEffect(() => {
    // if (hideCorporateList()) {
    //   return;
    // }
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/corporate`)
      .then(res => {
        setCorporateData(res.data.content)
        if (res.data.content.length > 0) {
          dispatch(getSelectedCorporate(res.data.content[0].id));
        }
      })
      .catch(function (error) {
      });
  }, [])

  function handleChange(event) {
    dispatch(getSelectedCorporate(event.target.value));
  }

  function hideCorporateList() {
    return isCorporateLevelUser() && getRefCount() === 1;
  }

  return (
    <Container>
      {corporateData && corporateData.length > 0 && <div className="single-card m-rl-m-8 pdf-hide">
        <div className="col-md-4 m-l-8" hidden={hideCorporateList()}>
          {/*<div className="form-group m-b-16 m-t-16">*/}
          {/*  <label htmlFor="email">{t("title.SELECT_CORPORATE")}</label>*/}
          {/*  <select onChange={handleChange}*/}
          {/*          className={`form-control`}*/}
          {/*          name="status">*/}
          {/*    {corporateData.map(item => (<option key={item.id} value={item.id}>{item.name}</option>*/}
          {/*    ))}*/}
          {/*  </select>*/}
          {/*</div>*/}
        </div>
        <CCOperationsTab/>
      </div>}
      {corporateData && corporateData.length === 0 && (
        <div className={"empty-results"}>
          <FeatherIcon icon="info"/>
          <div className={"empty-results-text"}>No corporate available, please add</div>
        </div>)
      }
    </Container>
  )
};


export default OperationsHeader
