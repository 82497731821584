import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import FormHandler from "../../../shared/utils/FormHandler";
import {validateKitForm} from "../../../utils/FormValidationRules";
import MultiSelect from "@khanacademy/react-multi-select";
import FeatherIcon from "feather-icons-react";

export default function ValveSettingRow(props) {

  let item = props.item
  let creatingIrrigation = props.creatingIrrigation
  let updatingIrrigation = props.updatingIrrigation
  let actuator = props.actuator


  const {values, errors, setValue, handleChange, handleSubmit, initForm, handleOnBlur} =
    FormHandler(submitKit, validateKitForm);

  const {t, i18n} = useTranslation();
  const [codeError, setCodeError] = useState("")
  const [onList, setOnList] = useState([]);
  const [offList, setOffList] = useState([]);
  const [isVisible, setIsVisible] = useState(true)

  function isLeaf(actuatorCode) {
    let filtered = props.unAssingedActuators.filter(e => e.code.includes(actuatorCode));
    return filtered.length === 1;
  }

  useEffect(() => {
    if (item.valveCode && item.valveCode !== "" && !props.isUpdatingList) {
      let found = props.creatingIrrigation.some(e => e.valveCode === item.valveCode)
      if (found) {
        if (!isVisible) {
          setIsVisible(true)
        }
        if (values.valveCode !== item.valveCode) {
          setValue({
            id: item.id,
            valveCode: item.valveCode,
            priorityLevel: item.priorityLevel,
            onList: item.onList,
            offList: item.offList
          })
        }
      }
    }

    if (item.valveCode === "" && !props.isUpdatingList) {
      if (!isVisible) {
        setIsVisible(true)
      }
      if (values.valveCode !== "") {
        setValue({
          valveCode: item.valveCode,
          priorityLevel: item.priorityLevel,
          onList: item.onList,
          offList: item.offList
        })
      }
    }
  }, [props.creatingIrrigation])


  useEffect(() => {
    setOnList(item.onList)
    setOffList(item.offList)
    setValue({
      id: item.id,
      valveCode: item.valveCode,
      priorityLevel: item.priorityLevel,
      onList: item.onList,
      offList: item.offList

    })
  }, [props.plotId])

  useEffect(() => {
    props.updateData(values, props.isUpdatingList, props.index)
  }, [values]);

  function submitKit() {

  }

  function checkUsedActuator(act) {

    let usedCode = []
    // if (act.assigned) {
    //   usedCode.push(act.code)
    // }

    updatingIrrigation.map((item) => usedCode.push(item.valveCode))
    creatingIrrigation.map((item) => usedCode.push(item.valveCode))

    let deletedIrrigationValveCodes = []
    props.deletedIrrigation.map((item) => deletedIrrigationValveCodes.push(item[0].valveCode))
    usedCode = usedCode.filter((item) => !deletedIrrigationValveCodes.includes(item))

    return usedCode.includes(act.code)
  }

  function onListSelect(selected) {
    setOnList(selected);
    setValue({onList: selected});
  }

  function offListSelect(selected) {
    setOffList(selected);
    setValue({offList: selected});
  }


  return (

    isVisible && <div className={"border-0"} style={{marginTop: 10}}>
      <div className={"flex-row"}>
        <div className={"max-w320 value-settings-select"}>

          <select
            disabled={!props.showAsignCancel}
            value={values.valveCode ? values.valveCode : ""}
            className={`form-control`}
            onChange={(e) => {
              props.setError(null)
              if (checkUsedActuator(e.target.value)) {
                setCodeError("Actuator already used")
                return
              } else {
                handleChange(e)
                setCodeError("")
              }

            }}
            onBlur={handleOnBlur}
            id="exampleFormControlSelect1"
            name="valveCode"
          >
            <option hidden value={""}>
              {t("instructions.SELECT_A_VALVE")}
            </option>
            {values.valveCode && (
              <option key={values.valveCode} value={values.valveCode}>
                {values.valveCode}
              </option>
            )}
            {actuator.length > 0 &&
              actuator
                .filter((actuator) => actuator.type !== "BRANCH" && !checkUsedActuator(actuator) && isLeaf(actuator.code))
                .map((actuator) => (
                  <option key={actuator.id} value={actuator.code}>
                    {actuator.code}
                  </option>
                ))}
            {actuator.filter((actuator) => actuator.type !== "BRANCH" && !checkUsedActuator(actuator) && isLeaf(actuator.code)).length === 0 && (
              <option disabled value="">
                {t("emptyMsg.NO_VALVE_AVAILABLE")}
              </option>
            )}
          </select>
          {codeError && (
            <p className="warning-input-msg">{codeError}</p>
          )}


        </div>

        <div className={"max-w320 value-settings-select"}>
          <input
            disabled={!props.showAsignCancel}
            value={values.priorityLevel || ''}
            className={`form-control`}
            onChange={handleChange}
            type="text" pattern="[0-9]*"
            placeholder={t("instructions.ENTER_PRIORITY_LEVEL")}

            id="exampleFormControlSelect1"
            name="priorityLevel"
          />


        </div>


        <div className={"max-w320 value-settings-select"}>
          <MultiSelect
            hieght={100}
            disabled={!props.showAsignCancel}
            style={{zIndex: 1000}}
            overrideStrings={{
              selectSomeItems: t("instructions.SELECT_ON_LIST")
            }}
            className={`form-control`}
            id="exampleFormControlSelect1"
            options={props.onOffListOptions}
            selected={onList}
            onSelectedChanged={onListSelect}
          />


        </div>


        <div className={"max-w320 value-settings-select"}>
          <MultiSelect
            disabled={!props.showAsignCancel}
            overrideStrings={{
              selectSomeItems: t("instructions.SELECT_OFF_LIST")
            }}
            className={`form-control`}
            id="exampleFormControlSelect1"
            options={props.onOffListOptions}
            selected={offList}
            onSelectedChanged={offListSelect}
          />


        </div>
        {props.showAsignCancel && <div className={"max-w320 value-settings-select"}>
          <button

            className="btn btn-sa-icon"
            // hidden={!irrigation.valveCode || isEditValve}
            onClick={() => {

              // console.log(props.deleteIrrigation(props.index, props.isUpdatingList,item))
              setIsVisible(props.deleteIrrigation(props.index, props.isUpdatingList, item))
            }}
          >
            <FeatherIcon className={"text-red icon-in-btn"} icon={"trash-2"}/>{" "}
            {t("button.DELETE")}
          </button>
        </div>}

      </div>

    </div>
  )
}