import React, {useEffect, useState} from 'react';
import Container from "react-bootstrap/Container";
import {useDispatch, useSelector} from "react-redux";
import FeatherIcon from "feather-icons-react";
import droplet from "../../../images/icons/droplet-28x28.svg";
import clock from "../../../images/clock.svg";
import ToggleLayout from "../../toggle-layout";
import PlotIrrigationHistory from "../plot/plot-irrigation-history";
import PlotIrrigationSetting from "../plot/plot-irrigation-setting";
import FarmIrrigationModeChangeHistory from "./irrigation-mode-change-history";
import {useTranslation} from "react-i18next";
import {isEmpty} from "../../../shared/utils/utils";

const FarmIrrigationSetting = () => {
  const {t, i18n} = useTranslation();
  const [selectedDropdownPlotId, setSelectedDropdownPlotId] = useState(null);
  const [visibleToggleIndex, setVisibleToggleIndex] = useState(0);

  const dispatch = useDispatch();
  useEffect(() => {

    setSelectedDropdownPlotId(" ")
  }, [])


  const farmSource = useSelector(state => {
    return state.farmList.farmSource;
  });

  return (
    <Container className={'pb-2'}>
      {!isEmpty(farmSource) ? (
        <div>

          <ToggleLayout visibleToggleIndex={visibleToggleIndex} toggleIndex={1} image={droplet}
                        title={t("title.IRRIGATION_CONTROL")}
                        onPlotChange={(plotId) => setSelectedDropdownPlotId(plotId)}
                        onVisibleToggleIndexChange={(index) => setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)}
                        dropDown={false}>
            <PlotIrrigationSetting isPlotSettings={false} isModeOnly={false} plotId={selectedDropdownPlotId}/>


          </ToggleLayout>
          {/*<ToggleLayout image={danger} title={'Irrigation Control'}>*/}

          {/*{farmSource && farmSource.type === ACTUATOR_TYPE.MIST &&*/}
          {/*<IrrigationCycleSetting farmSource={farmSource}/>*/}
          {/*}*/}
          {/*<FarmIrrigationModeChange onChange={() => setLoadMore(true)} farmId={farmId}/>*/}
          {/*/!*<div className="table-container-heading">Irrigation Mode Change History</div>*!/*/}

          {/*</ToggleLayout>*/}
          <ToggleLayout
            onVisibleToggleIndexChange={(index) => setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)}
            visibleToggleIndex={visibleToggleIndex} toggleIndex={2} image={clock}
            title={t("title.MODE_CHANGE_HISTORY")}>

            <FarmIrrigationModeChangeHistory/>

          </ToggleLayout>

          <ToggleLayout
            onVisibleToggleIndexChange={(index) => setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)}
            visibleToggleIndex={visibleToggleIndex} toggleIndex={3}
            imgType={"feather"} feather={"file"} dropDown={true} title={t("title.IRRIGATION_HISTORY")}
            onPlotChange={(plotId) => setSelectedDropdownPlotId(plotId)}>
            {!selectedDropdownPlotId || selectedDropdownPlotId === 'FARM' ?
              <div className={"empty-results"}>
                <FeatherIcon icon="info"/>
                <div className={"empty-results-text"}>{t("info.PLEASE_SELECT_A_PLOT")}</div>
              </div>
              :
              <PlotIrrigationHistory plotId={selectedDropdownPlotId} showHistory={true}/>
            }
          </ToggleLayout>


        </div>
      ) : (
        <div className={"empty-results"}>
          <FeatherIcon icon="info"/>
          <div className={"empty-results-text"}>{t("info.THERE_ARE_NO_WATER_SOURCES_CONFIGURED")}</div>
        </div>
      )}
    </Container>
  )
};

export default FarmIrrigationSetting
