export const getFarmList = (farmList) => {
  return {
    type: "FARM_LIST",
    payload: farmList
  };
};
export const getFilterFarmList = (filterFarmList) => {
  return {
    type: "FILTER_FARM_LIST",
    payload: filterFarmList
  };
};

export const getSelectedFarm = (farm) => {
  return {
    type: "SELECTED_FARM",
    payload: farm
  };
};

export const getSelectedFarmScout = (scout) => {
  return {
    type: "SELECTED_FARM_SCOUT",
    payload: scout
  };
};

export const getFarmSource = (payLoad) => {
  return {
    type: "FARM_SOURCE",
    payload: payLoad
  };
};

export const getAllFarmSources = (payLoad) => {
  return {
    type: "FARM_SOURCE_ALL",
    payload: payLoad
  };
};

export const getFarmSourceIndex = (payLoad) => {
  return {
    type: "FARM_SOURCE_INDEX",
    payload: payLoad
  };
};
export const getFarmActuators = (payLoad) => {
  return {
    type: "FARM_ACTUATOR",
    payload: payLoad
  };
};
export const getFarmMqtt = (payLoad) => {
  return {
    type: "FARM_MQTT_STATUS",
    payload: payLoad
  };
};

