export const changeToggle = (toggle) => {
  return {
    type: "TOGGLE_STATE",
    payload: toggle
  };
};

export const changeView = (view) => {
  return {
    type: "CHANGE_VIEW",
    payload: view
  };
};

export const toggleChangePassword = (view) => {
  return {
    type: "CHANGE_PASSWORD",
    payload: view
  };
};

export const toggleConfirmationDialog = (view) => {
  return {
    type: "CONFIRMATION_DIALOG",
    payload: view
  };
};

export const toggleTimePicker = (view) => {
  return {
    type: "TIME_PICKER",
    payload: view
  };
};

export const toggleLoader = (view) => {
  return {
    type: "TOGGLE_LOADER",
    payload: view
  };
};
export const toggleLoaderClear = (view) => {
  return {
    type: "TOGGLE_LOADER_CLEAR",
    payload: view
  };
};

export const toggleLoaderMulti = (view) => {
  return {
    type: "TOGGLE_LOADER_MULTI",
    payload: view
  };
};

export const resetConfirmationDialog = () => {
  return {
    type: "CONFIRMATION_DIALOG",
    payload: null
  };
};
export const getSelectedCorporate = (corporateId) => {
  return {
    type: "SELECTED_CORPORATE",
    payload: corporateId
  };
};
