import React, {useEffect, useState} from 'react'
import {GoogleMap, Polygon, withGoogleMap, withScriptjs} from "react-google-maps"
import {StandaloneSearchBox,} from '@react-google-maps/api';
import {compose, withProps} from "recompose";
import {DrawingManager} from "react-google-maps/lib/components/drawing/DrawingManager";
import {option} from "react-timekeeper/lib/components/styles/time-dropdown";
import FormHandler from "../../../shared/utils/FormHandler";
import {PlotAddValid} from "../../../utils/FormValidationRules";
import axios from "axios";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {toggleLoader, toggleLoaderClear} from "../../../shared/actions/setting";
import * as _ from "underscore";
import imageCompression from "browser-image-compression";
import {uploadImage} from "../../../utils/Utils";
import {useParams} from "react-router-dom";
import {getPlotList} from "../../../actions/plot";
import {getUserId} from "../../../shared/utils/SharedAuthentication";

function getPolygonCoords(e, coordinatess, plotList) {
  let len = e.getPath().getLength();
  let err = null;
  let coordinates = [];
  let isInsideFarm = [];
  let isInsidePlot = [];

  if (len >= 3) {
    for (let i = 0; i < len; i++) {
      let latlan = {}
      latlan.lat = e.getPath().getArray()[i].lat();
      latlan.lng = e.getPath().getArray()[i].lng();
      coordinates.push(latlan)
    }
    for (let i = 0; i < e.getPath().length; i++) {
      const currentCoordinates = e.getPath().getAt(i);
      let latlan = {}
      latlan.lat = e.getPath().getArray()[i].lat();
      latlan.lng = e.getPath().getArray()[i].lng();
      coordinates.push(latlan)
      const google = window.google
      console.log(e.getPath())
      const bermudaTriangle = new google.maps.Polygon({paths: coordinatess});
      isInsideFarm[i] = google.maps.geometry.poly.containsLocation(currentCoordinates, bermudaTriangle);

      for (let i = 0; i < plotList.length; i++) {
        console.log(plotList.length, i)
        let bermudaTrianglePlot = new google.maps.Polygon({paths: plotList[i].coordinates});
        let data = google.maps.geometry.poly.containsLocation(currentCoordinates, bermudaTrianglePlot);
        console.log(data)
        isInsidePlot.push(data)
        console.log(isInsidePlot)
      }
    }
    if (isInsideFarm.includes(false)) {
      err = 'A Plot should be inside the farm boundary and outside the existing plot boundaries'
    } else if (isInsidePlot.includes(true)) {
      err = 'Plots can not be intersect each other'
    }

  } else {
    err = 'A Plot should consist at least 3 points'
  }
  return {coordinates: coordinates, err: err}
}


const MyMapComponent = compose(
  withProps({

    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDobm_3OLsu0cFTLt-zUAgt-_7Oi-TkGT4&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{height: `100%`}}/>,
    containerElement: <div style={{height: `310px`}}/>,
    mapElement: <div style={{height: `100%`}}/>
  }),
  withScriptjs,
  withGoogleMap
)(props => {


  const [map, setMap] = useState(null);
  const [mapDraw, setMapDraw] = useState(null);
  const selectedFarm = useSelector(state => {
    return state.farmList.selectedFarm;
  });

  const plotList = useSelector(state => {
    return state.plotList.plotList
  });


  function fitBounds(coordinates) {
    if (!coordinates) {
      return;
    }
    const google = window.google;
    const bounds = new google.maps.LatLngBounds();

    coordinates.map((hotel, i) => {
      const position = new google.maps.LatLng(hotel.lat, hotel.lng);
      bounds.extend(position);
    });

    map.fitBounds(bounds);
  }

  useEffect(() => {
    if (!map || !selectedFarm.id) {
      return;
    }
    fitBounds(selectedFarm.coordinates);
  }, [selectedFarm, map]);

  const [searchBox, setSearchBox] = useState(null);
  const onPlacesChanged = () => {
    const places = searchBox.getPlaces();
    // console.log(place.geometry.location)

    const google = window.google;
    const bounds = new google.maps.LatLngBounds();
    //
    // coordinates.map((hotel, i) => {
    //     const position = new google.maps.LatLng(hotel.lat, hotel.lng);
    //     bounds.extend(position);
    // });
    //
    places.forEach((place) => {
      // console.log(place.geometry.location)
      if (place.geometry.viewport) {
        // Only geocodes have viewport.
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
    })
    map.fitBounds(bounds);

  };
  const onSBLoad = ref => {
    setSearchBox(ref);
  };

  const {t} = useTranslation();
  return (

    <div className={'position-relative'}>
      <div className={"text-center mt-3"}>{t("title.DRAW_PLOT")}</div>

      <GoogleMap ref={mapRef => {
        setMap(mapRef);
      }}

      >

        {selectedFarm.coordinates && <DrawingManager
          defaultDrawingMode={'polygon'}
          defaultOptions={{
            drawingControl: true,
            drawingControlOptions: {
              drawingModes: ['polygon'],
            }
          }}

          onPolygonComplete={(e) => {
            props.coordinate(getPolygonCoords(e, selectedFarm.coordinates, plotList))
          }}
        />}
        {selectedFarm.coordinates && <Polygon
          path={selectedFarm.coordinates}
          //key={1}
          options={{
            fillColor: "#000",
            fillOpacity: 0.4,
            strokeColor: "#000",
            strokeOpacity: 1,
            strokeWeight: 1
          }}/>}

        {plotList.length > 0 && (plotList.filter(plot => plot.coordinates).map((plot, index) =>
          <div key={'plot' + index}>

            <Polygon
              path={plot.coordinates}
              key={index}
              editable={false}
              options={{
                geodesic: true,
                fillColor: "#A3A3A37A",
                fillOpacity: 1,
                strokeColor: "#50565FA3",
                strokeOpacity: 1,
                strokeWeight: 1,
                zIndex: 1000,
              }}
            />
          </div>
        ))
        }


        <StandaloneSearchBox
          onPlacesChanged={onPlacesChanged}
          onLoad={onSBLoad}
        >
          <input
            type="text"
            placeholder={t("placeHolder.SEARCH_PLACE")}
            style={{
              boxSizing: 'border-box',
              border: `1px solid transparent`,
              width: `270px`,
              height: `40px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.1)`,
              fontSize: `14px`,
              outline: `none`,
              margin: 'center',
              textOverflow: `ellipses`,
              position: 'absolute',
              bottom: '298px',
              marginLeft: '40%'
            }}
          />
        </StandaloneSearchBox>


      </GoogleMap>

    </div>
  )
});


export default function PlotAdd(props) {


  const [isLoading, setIsLoading] = useState(false);
  const [isMapAvailable, setIsMapAvailable] = useState(true);
  const [fileName, setFileName] = useState(null);
  const [isUpload, setIsUpload] = useState(false);
  const [formErr, setFormErr] = useState(null);
  const [errMap, setErrMap] = useState(null);
  const [thumbnail, setThumbnail] = useState(false);
  const [plotReload, setPlotReload] = useState(false);
  const [optionsArray, setOptionsArray] = useState([]);
  const dispatch = useDispatch();
  const {farmId} = useParams();
  const plotList = useSelector(state => {
    return state.plotList.plotList
  });

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleOnBlur,
    initForm,
    deleteErrors,
  } = FormHandler(plotAdd, PlotAddValid);

  function plotAdd() {

    setIsLoading(true)
  }


  function coordinates(e) {
    values.coordinates = e.coordinates
    setErrMap(e.err)
  }

  async function uploadPlotImage(e) {
    e.persist()
    const imageFile = e.target.files[0];
    const thumbnail = "thumbnail_" + new Date().getTime() + '.jpeg';
    const imageName = "image_" + new Date().getTime() + '.jpeg';
    setFileName(imageName)


    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 96,
      useWebWorker: true
    }
    imageCompression(imageFile, options)
      .then(async function (compressedFile) {

        let response = await uploadImage(thumbnail, compressedFile);
        values.imageThumbnail = response;
        setThumbnail(true)

      })
      .catch(function (error) {

      });

    let response = await uploadImage(imageName, imageFile);
    values.image = response;
    setIsUpload(true)


  }

  function resetForm() {
    const values = {};
    initForm({...values});
    Object.keys(values).forEach(key => delete values[key]);
    deleteErrors(errors);
    setFormErr("");
    setIsUpload(false);
    setThumbnail(false)
    setIsLoading(false)
  }

  useEffect(() => {
    dispatch(toggleLoader(true));
    axios.get(
      process.env.REACT_APP_HOST + `/user/` + getUserId() + `/cropAgronomy`
    )
      .then((res) => {
        let cropNames = _.pluck(res.data.content, 'cropName')
        setOptionsArray(cropNames);

      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, []);

  useEffect(() => {
    if (!isLoading) {
      return
    }
    values.farmId = farmId
    if (fileName) {
      if (!values.imageThumbnail || !values.image) {
        dispatch(toggleLoader(true))
      } else {
        dispatch(toggleLoader(true))
        axios.post(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot`, values)
          .then(res => {
            toast.success("Plot Created successfully");
            resetForm()
            setFileName(null)
            setIsUpload(false)
            dispatch(toggleLoader(false))
            props.onClose()
            let data = plotList
            data.push(res.data.content)
            dispatch(getPlotList(data));

          }).catch(error => {
            if (error.response && error.response.status === 422) {
              setFormErr(error.response.data.message);
              dispatch(toggleLoader(false))
            }

          }
        ).finally(() => {
          setPlotReload(true);
          dispatch(toggleLoaderClear(true))

        })

      }
    } else {

      dispatch(toggleLoader(true))
      axios.post(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot`, values)
        .then(res => {
          toast.success("Plot Created successfully");
          resetForm()
          setFileName(null)
          setIsUpload(false)
          dispatch(toggleLoader(false))
          let data = plotList
          data.push(res.data.content)
          dispatch(getPlotList(data));
          props.onClose()

        }).catch(error => {
          if (error.response && error.response.status === 422) {
            setFormErr(error.response.data.message);
          }

        }
      ).finally(() => {
        setPlotReload(true);
        dispatch(toggleLoader(false))

      })

    }

  }, [isLoading, thumbnail, isUpload])


  useEffect(() => {
    if (!plotReload) {
      return;
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/plot')
      .then(function (response) {
        dispatch(getPlotList(response.data.content));
      })
      .catch(function (error) {
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, [plotReload]);

  const {t} = useTranslation();
  return (
    <div className={'px-3'}>
      <div hidden={!isMapAvailable}>
        <MyMapComponent coordinate={coordinates}/>
      </div>


      {!isMapAvailable && <form onSubmit={handleSubmit}>
        <div className="row sa-modal-content">
          <div className="col-md-6">
            <div className="form-group m-b-16">
              <label htmlFor="email">{t("title.PLOT_NAME")}</label>
              <input onBlur={handleOnBlur} onChange={handleChange}
                     value={values.name || ''} type="text"
                     className={`form-control ${errors.name && "warning-input"}`}
                     name="name"
                     placeholder={t("placeHolder.PLOT_FARM_NAME")}/>
              {errors.name && (<p className="warning-input-msg ">{errors.name}</p>)}
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group m-b-16">
              <label htmlFor="email">{t("title.CROP")}</label>
              <select
                onBlur={handleOnBlur} onChange={handleChange}
                value={values.crop || ''} type="text"
                className={`form-control ${errors.crop && "warning-input"}`}
                name="crop"
                placeholder={t("title.CROP_NAME")}
              >
                <option value={""}>{t("placeHolder.SELECT_A_CROP")}</option>
                {optionsArray.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>
              {errors.crop && (<p className="warning-input-msg ">{errors.crop}</p>)}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group m-b-16">
              <label htmlFor="email">{t("title.ESTIMATED_AREA")}</label>
              <input onBlur={handleOnBlur} onChange={handleChange}
                     value={values.area || ''} type="text"
                     className={`form-control ${errors.area && "warning-input"}`}
                     name="area"
                     placeholder={t("placeHolder.ENTER_ESTIMATED_AREA")}/>
              {errors.area && (<p className="warning-input-msg ">{errors.area}</p>)}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group m-b-16">
              <label htmlFor="email">{t("title.KIT_ID")}</label>
              <input onBlur={handleOnBlur} onChange={handleChange}
                     value={values.kitId || ''} type="text"
                     className={`form-control`}
                     name="kitId"
                     placeholder={t("placeHolder.ENTER_KIT_ID")}/>

            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group m-b-16">
              <label htmlFor="email">{t("title.FARM_IMAGE")}</label>
              <div className="custom-file">
                <input type="file" className="form-group m-b-16" id="customFile" onChange={uploadPlotImage}
                       accept="image/*"/>
                <label className="custom-file-label farm-image-upload d-flex align-items-center sa-cursor"
                       htmlFor="customFile">{fileName ? fileName : t("title.FARM_IMAGE")}</label>
              </div>
              {/*{errors.contactEmail && (<p className="warning-input-msg ">{errors.contactEmail}</p>)}*/}
            </div>
          </div>

        </div>

        {formErr && (<div className={"login-warning-msg"}>{formErr}</div>)}

        <div className="sa-modal-footer">
          <div className="sa-modal-footer-btn">
            <button type={"button"} className="btn btn-sa-text w-120p" onClick={() => props.onClose()}>
              {t("button.CANCEL")}
            </button>
            <button className="btn btn-sa-primary w-120p" type="submit">
              {t("button.DONE")}
            </button>
          </div>
        </div>
      </form>}

      {isMapAvailable && <div className="sa-modal-footer">
        {errMap && <div className={'login-warning-msg mt-3'}>{errMap}</div>}
        <div className="sa-modal-footer-btn">
          <button type={"button"} className="btn btn-sa-text w-120p" onClick={() => props.onClose()}>
            {t("button.CANCEL")}
          </button>
          <button className="btn btn-sa-primary w-120p"
                  onClick={() => {
                    if (errMap || !values.coordinates) {
                      if (!values.coordinates) {
                        setErrMap("Please draw plot")
                      }
                      return
                    }
                    setIsMapAvailable(false)
                  }}>
            {t("button.NEXT")}
          </button>
        </div>
      </div>}


    </div>
  )
}
