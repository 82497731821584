const INIT_STATE = {
  farmList: [],
  selectedFarm: {},
  farmSource: {},
  farmSourceIndex: 0,
  farmMqttStatus: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case "FARM_LIST": {
      return {
        ...state,
        farmList: action.payload
      };
    }
    case "FILTER_FARM_LIST": {
      return {
        ...state,
        filterFarmList: action.payload
      };
    }
    case "SELECTED_FARM": {
      return {
        ...state,
        selectedFarm: action.payload
      };
    }
    case "SELECTED_FARM_SCOUT": {
      return {
        ...state,
        selectedFarmScout: action.payload
      };
    }
    case "FARM_SOURCE": {
      return {
        ...state,
        farmSource: action.payload
      };
    }
    case "FARM_SOURCE_ALL": {
      return {
        ...state,
        farmSourceAll: action.payload
      };
    }
    case "FARM_SOURCE_INDEX": {
      return {
        ...state,
        farmSourceIndex: action.payload
      };
    }
    case "FARM_ACTUATOR": {
      return {
        ...state,
        farmActuators: action.payload
      };
    }
    case "FARM_MQTT_STATUS": {
      return {
        ...state,
        farmMqttStatus: action.payload
      };
    }
    default:
      return state;

  }
}


